import { Modal, Button as ButtonBoot } from 'react-bootstrap';
import { useTitleAndUser } from '../../../../../hooks/userTitle';
import { LinkB3Content } from './stylesIntegrarCarteira';
import { useState } from 'react';
import { LinkAccountB3 } from '../../../../components/Dashboard/Settings/LinkAccountB3';
import { useHistory } from 'react-router-dom';
import { ModalDesigner } from '../../../../../components/ModalDesigner';

export function LinkB3AllPagesCarteira() {
  const history = useHistory();
  const listLinkedAccountB3 = localStorage.getItem('listUserLinkedAccountB3');
  //@ts-ignore
  const { user } = useTitleAndUser();
  const [modalLinkAccountB3, setModalLinkAccountB3] = useState(false);

  const listLinkedAccountB3Json =
    listLinkedAccountB3 !== null ? JSON.parse(listLinkedAccountB3) : [];

  const showRedirectWallet = !listLinkedAccountB3Json.includes(user.email);

  function handleUserActionRedirectB3AndRedirectWallet() {
    if (showRedirectWallet && user.carteira === 'automatico') {
      localStorage.setItem(
        'listUserLinkedAccountB3',
        JSON.stringify([...listLinkedAccountB3Json, user.email])
      );

      return history.push('/my-wallet');
    }
    return setModalLinkAccountB3(!modalLinkAccountB3);
  }

  return (
    <>
      {showRedirectWallet && !(user.carteira === 'automatico') && (
        <LinkB3Content
          isLinked={showRedirectWallet && user.carteira === 'automatico'}
        >
          <img src="https://atendimento.b3.com.br/logo-b3-white.png" alt="" />
          {window.screen.width >= 768 && (
            <p>
              {showRedirectWallet && user.carteira === 'automatico'
                ? 'Sua carteira foi vinculada com sucesso, acesse já!'
                : 'Conecte sua carteira da B³ e aproveite ao máximo o iBox6.'}
            </p>
          )}
          <div className="divider" />
          <button
            type="button"
            onClick={handleUserActionRedirectB3AndRedirectWallet}
          >
            {showRedirectWallet && user.carteira === 'automatico'
              ? 'Acessar carteira'
              : 'Integrar carteira'}
          </button>
        </LinkB3Content>
      )}
      <ModalDesigner
        id="linkb3"
        isOpen={modalLinkAccountB3}
        setState={setModalLinkAccountB3}
        size="sm"
        title="Vincular conta B3"
      >
        <LinkAccountB3 onCloseModal={setModalLinkAccountB3} />
      </ModalDesigner>
    </>
  );
}
