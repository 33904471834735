import pdfMake from 'pdfmake/build/pdfmake';
//@ts-ignore
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { IPropsIncomeAnnual, IPropsIncomeData } from '../../@types';
import moment from 'moment';
import { transformMaskCurrency } from '../../../../../utils/transformMaskCurrency';

interface IPropsGeneratorPDF {
  data: IPropsIncomeAnnual[];
  data1: IPropsIncomeData[];
  data2: IPropsIncomeData[];
  data3: IPropsIncomeData[];
  data4: IPropsIncomeData[];
}

export function generatorPDFIncomeTable({
  data1,
  data2,
  data3,
  data4,
  data,
}: IPropsGeneratorPDF) {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  const getBonificacaoTotal = (item: IPropsIncomeAnnual) => {
    try {
      if (!item) return 0;
      const precoMedio = item.ValorTotalAtual / item.QuantidadeAtual;
      const boni = data4?.find(
        (boniItem) => boniItem.tickerSymbol === item.tickerSymbol
      );
      if (!boni) return 0;
      return Math.floor(boni.Quantity) * precoMedio;
    } catch (error) {
      console.log('Boni', error);
      return 0;
    }
  };
  const getBonificacaoQtd = (item: IPropsIncomeAnnual) => {
    try {
      if (!item) return 0;
      const boni = data4?.find(
        (boniItem) => boniItem.tickerSymbol === item.tickerSymbol
      );
      if (!boni) return 0;
      return boni.Quantity;
    } catch (error) {
      console.log('BoniQtd', error);
      return 0;
    }
  };
  const reportTitle: any = [
    {
      text: `Imposto de renda ${moment().format('YYYY')}`,
      fontSize: 16,
      bold: true,
      margin: [15, 20, 0, 45],
    },
  ];
  const headerDefault = [
    {
      text: 'CNPJ',
      style: 'tableHeader',
      fontSize: 9,
      margin: [0, 2, 0, 2],
    },
    {
      text: 'Fonte pagadora',
      style: 'tableHeader',
      fontSize: 9,
      margin: [0, 2, 0, 2],
    },

    {
      text: 'Valor',
      style: 'tableHeader',
      fontSize: 9,
      margin: [0, 2, 0, 2],
    },
  ];
  const headerDefaultDescription = [
    {
      text: 'CNPJ',
      style: 'tableHeader',
      fontSize: 9,
      margin: [0, 2, 0, 2],
    },
    {
      text: 'Fonte pagadora',
      style: 'tableHeader',
      fontSize: 9,
      margin: [0, 2, 0, 2],
    },
    {
      text: 'Descrição',
      style: 'tableHeader',
      fontSize: 9,
      margin: [0, 2, 0, 2],
    },

    {
      text: 'Valor',
      style: 'tableHeader',
      fontSize: 9,
      margin: [0, 2, 0, 2],
    },
  ];

  const filterAction = data.filter((item) => item.Tipo === 'Ações');
  const filterFIIs = data.filter((item) => item.Tipo === 'FII');

  const newDataAction = filterAction.map((item) => {
    return [
      {
        text: ['CNPJ: ', { text: item.CNPJ, bold: true }],
        fontSize: 10,
      },
      {
        text: [
          'Discriminação:',
          {
            text: `${item.tickerSymbol} - ${item.NomeEmpresa} CNPJ: ${
              item.CNPJ
            } - QUANTIDADE: ${
              item.QuantidadeAtual + Math.floor(getBonificacaoQtd(item))
            } - ESCRITURADOR: ${item.Escriturador}`,
            bold: true,
          },
        ],
      },

      {
        text: [
          'Situação anterior: ',
          {
            text: transformMaskCurrency(item.ValorTotalAnterior),
            bold: true,
          },
        ],
      },
      {
        text: [
          'Situação atual: ',
          {
            text: transformMaskCurrency(
              item.ValorTotalAtual + getBonificacaoTotal(item)
            ),
            bold: true,
          },
        ],
        margin: [0, 0, 0, 16],

        fontSize: 10,
      },
    ];
  });

  const newDataFIIs = filterFIIs.map((item) => {
    return [
      {
        text: ['CNPJ: ', { text: item.CNPJ, bold: true }],
        fontSize: 10,
      },
      {
        text: [
          'Discriminação:',
          {
            text: `${item.tickerSymbol} - ${item.NomeEmpresa} CNPJ: ${item.CNPJ} - QUANTIDADE: ${item.QuantidadeAtual}  - Administradora: ${item.AdministradoraFII} - CNPJ da administradora: ${item.CNPJAdministradora}`,
            bold: true,
          },
        ],
      },

      {
        text: [
          'Situação anterior: ',
          {
            text: transformMaskCurrency(item.ValorTotalAnterior),
            bold: true,
          },
        ],
      },
      {
        text: [
          'Situação atual: ',
          {
            text: transformMaskCurrency(item.ValorTotalAtual),
            bold: true,
          },
        ],
        margin: [0, 0, 0, 16],

        fontSize: 10,
      },
    ];
  });

  console.log({ data, data4 });
  const newData1 = data1.map((item) => {
    return [
      {
        text: item.CNPJ,
        fontSize: 10,
      },
      {
        text: `${item.tickerSymbol} - ${item.NomeEmpresa}`,
        fontSize: 10,
      },

      {
        text: transformMaskCurrency(item.operationValue),
        fontSize: 10,
      },
    ];
  });
  const newData2 = data2.map((item) => {
    return [
      {
        text: item.CNPJ,
        fontSize: 10,
      },
      {
        text: `${item.tickerSymbol} - ${item.NomeEmpresa}`,
        fontSize: 10,
      },

      {
        text: transformMaskCurrency(item.operationValue),
        fontSize: 10,
      },
    ];
  });
  const newData3 = data3.map((item) => {
    return [
      {
        text: item.CNPJ,
        fontSize: 10,
      },
      {
        text: `${item.tickerSymbol} - ${item.NomeEmpresa}`,
        fontSize: 10,
      },
      {
        text: `Rendimentos recebidos de ${item.NomeEmpresa}`,
        fontSize: 10,
      },

      {
        text: transformMaskCurrency(item.operationValue),
        fontSize: 10,
      },
    ];
  });
  const newData4 = data4.map((item) => {
    return [
      {
        text: item.CNPJ,
        fontSize: 10,
      },
      {
        text: `${item.tickerSymbol} - ${item.NomeEmpresa}`,
        fontSize: 10,
      },
      {
        text: `${transformMaskCurrency(item.operationValue)}  -  Quantidade: ${
          item?.Quantity || '-'
        }`,
        fontSize: 10,
      },
    ];
  });
  const details: any = [
    {
      text: 'As taxas apresentadas incluem somente as tarifas de liquidação, registro, termo/opções e emolumentos da B3. O Usuário é exclusivamente responsável pela conferência e validação das informações utilizadas na apuração do imposto devido, conforme Termos e Condições Gerais de Uso.',
      fontSize: 10,
      margin: [0, 0, 0, 16],
    },
    {
      text: 'Grupo 03 - Participações societárias',
      fontSize: 15,

      bold: true,
    },
    {
      text: 'Código 01 - Ações (inclusive as listadas na bolsa Ações)',
      fontSize: 15,

      margin: [0, 0, 0, 16],
      bold: true,
    },
    newDataAction,
    {
      text: 'Grupo 07 - Fundos',
      fontSize: 15,

      bold: true,
    },
    {
      text: 'Código 03 - Fundo de Investimento Imobiliário FII',
      fontSize: 15,

      margin: [0, 0, 0, 16],
      bold: true,
    },

    newDataFIIs,
    {
      text: 'Código 09 – Lucros e dividendos recebidos (Ações)',
      style: 'subheader',
      margin: [0, 16, 0, 0],
    },
    {
      table: {
        headerRows: 1,
        widths: ['*', '*', '*'],
        body: [headerDefault, ...newData1],
      },
      layout: 'headerLineOnly',
    },

    {
      text: 'Código 10 – Juros sobre capital próprio',
      style: 'subheader',
      margin: [0, 16, 0, 0],
    },
    {
      table: {
        headerRows: 1,
        widths: ['*', '*', '*'],
        body: [headerDefault, ...newData2],
      },
      layout: 'headerLineOnly',
    },
    {
      text: 'Código 18 – Incorporação de reservas ao capital / bonificações em ações',
      style: 'subheader',
      margin: [0, 16, 0, 0],
    },
    {
      table: {
        headerRows: 1,
        widths: ['*', '*', '*', '*'],
        body: [headerDefault, ...newData4],
      },
      layout: 'headerLineOnly',
    },
    {
      text: 'Verifique os fatos relevantes da empresa para determinar o valor da bonificação.',
      style: 'body',
      margin: [0, 8, 0, 0],
    },
    {
      text: 'Código 26 - Outros - (Rendimentos Fundo Imobiliário - FII)',
      style: 'subheader',
      margin: [0, 16, 0, 0],
    },
    {
      table: {
        headerRows: 1,
        widths: ['*', '*', '*', '*'],
        body: [headerDefaultDescription, ...newData3],
      },
      layout: 'headerLineOnly',
    },
  ];
  function rodape(currentPage: number, pageCount: number) {
    return [
      {
        text: `${currentPage} / ${pageCount}`,
        alignment: 'right',

        fontSize: 9,
        margin: [0, 10, 20, 0],
      },
    ];
  }

  const buildPDF = {
    pageSize: 'A4',
    pageMargins: [15, 50, 15, 40],

    header: [reportTitle],
    content: [details],
    footer: rodape,
  };
  //@ts-ignore
  pdfMake.createPdf(buildPDF).download('Imposto de renda');
}
