import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

export const ContentDataHeader = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 1rem;
  
  p {
    margin: 0;
  }
`;

export const CardContentData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 4px;
  border-top: 1px solid #EC6643;
  min-width: 8rem;
  width: 14rem;
  box-shadow: 1px 1px 6px 0px rgba(48, 63, 75, 0.42);

  padding: 0.5rem;

  p {
    margin: 0;
    font-size: 1rem;
    color: ${(props) => props.theme['gray-500']};
  }
  strong {
    font-weight: 600;
    font-size: 1rem;
    color: ${(props) => props.theme['gray-500']};
  }
`;

export const HeaderDividendosModal = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: 1rem;
  gap: 1rem;
  .textPayout {
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
    color: '#606060';
  }
  .contentCards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
  }

  .textWarning {
    margin-bottom: 0;
    font-weight: 400;
    font-size: 0.625rem;
    color: #707070;
    padding: 0.25rem 0.5rem;
    background-color: #efefef;
    border-radius: 14px;
    width: 100%;
    max-width: 17.5rem;
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }
`;

export const CardContentDataHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 4px;
  border-top: 1px solid #EC6643;
  min-width: 8rem;
  width: 14rem;
  box-shadow: 1px 1px 6px 0px rgba(48, 63, 75, 0.42);

  padding: 0.5rem;

  p {
    margin: 0;
    font-size: 1rem;
    color: ${(props) => props.theme['gray-500']};
  }
  strong {
    font-weight: 600;
    font-size: 1.25rem;
    color: ${(props) => props.theme['gray-500']};
  }
  span {
    margin: 0;
    font-size: 0.825rem;
    color: ${(props) => props.theme['gray-500']};
  }
`;
