import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* padding: 1rem; */
  width: 100%;
  max-width: 300px;
  height: 200px;
  justify-content: space-between;
  border: ${(props) => props.theme.border};
  border-radius: 8px;
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 1rem 0;
    background: #303F4B; //${(props) => props.theme.primary};
    color: ${(props) => props.theme.white};
    padding: 0 1rem;
    div {
      display: flex;
      gap: 0.5rem;
      align-items: center;

      .estado {
        font-size: 1rem;
        font-weight: 600;
      }
    }
  }
  main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 0 1rem 1rem 1rem;
    color: ${(props) => props.theme['gray-700']};

    h3 {
      font-size: 1.25rem;
      font-weight: 600;
      margin-bottom: 0.25rem;
    }
    a {
      color: ${(props) => props.theme['gray-700']};
      font-size: 0.875rem;
      :hover {
        color: ${(props) => props.theme.primary};
      }
    }

    /* .inadimplencia {
      color: ${(props) => props.theme.error};
    } */
  }
  footer {
    display: flex;
    justify-content: space-between;
    height: 100%;
    align-items: flex-end;
    span {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      strong {
        font-weight: 600;
        font-size: 1rem;
        color: ${(props) => props.theme['gray-700']};
      }
    }
  }
`;
