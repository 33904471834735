import ScrollContainer from 'react-indiana-drag-scroll';
import styled, { css } from 'styled-components';

export const GraphicCotationHistoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 68vw;
  max-width: 100%;
  /* width: 100%; */

  .TooltipContentGraphic {
    display: flex;
    flex-direction: column;
    background: ${(props) => props.theme.white};
    border-radius: 4px;

    header {
      background: ${(props) => props.theme['gray-100']};
      padding: 0.5rem;
      color: ${(props) => props.theme.white};
    }
    div {
      padding: 0.5rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }
  }
`;

export const HeaderGraphic = styled(ScrollContainer)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  h2 {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    font-weight: 500;
    font-size: 2rem;
    color: ${(props) => props.theme['gray-600']};
    margin-right: 1.5rem;
  }
`;

interface IPropsPercentValue {
  upValue: boolean;
}

export const PercentValue = styled.span<IPropsPercentValue>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;

  background: ${(props) => (props.upValue ? '#E8FFEA' : '#FFE8E8')};
  color: ${(props) =>
    props.upValue ? props.theme['success-300'] : props.theme['danger-300']};
  padding: 0.25rem 0.5rem;
  border-radius: 39px;

  font-weight: 400;
  font-size: 1.5rem;
`;

export const FiltersDate = styled(ScrollContainer)`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  box-shadow: 1px 1px 6px 0px rgba(48, 63, 75, 0.42);
  border-radius: 8px;
  padding: 1rem;
  max-width: fit-content;
  white-space: nowrap;
`;

export interface IPropsFilterSelect {
  isSelected: boolean;
}

export const FilterSelect = styled.button<IPropsFilterSelect>`
  all: unset;

  font-weight: 500;
  font-style: 0.875rem;
  color: ${(props) => props.theme['gray-100']};

  ${(props) =>
    props.isSelected &&
    css`
      color: #EC6643;
      text-decoration: none;
    `}
`;

export const CardHeaderValue = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  border-top: 1px solid #EC6643;
  min-width: 12rem;
  width: 18rem;
  border-radius: 8px;
  padding: 0.5rem;
  box-shadow: 1px 1px 6px 0px rgba(48, 63, 75, 0.42);
  margin-bottom: 0.25rem;
  h6 {
    font-size: 1.25rem;
    color: ${(props) => props.theme['gray-500']};
    margin-bottom: 0.25rem;
  }

  strong {
    font-weight: 600;
    font-size: 1.25rem;
    color: ${(props) => props.theme['gray-500']};
    margin-bottom: 0.5rem;
  }
  span {
    font-size: 1rem;
    font-weight: 400;
    color: ${(props) => props.theme['gray-400']};
  }
`;
