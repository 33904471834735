import styled from 'styled-components';

export const ContainerLogin = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  background-color: #fff;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
`;
export const AsideContent = styled.aside`
  height: 100vh;
  width: 100%;
  img {

    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  @media (max-width: 768px) {
    display: none;
  }
  @media (max-width: 768px) {
    display: none;
  }
  @media (max-width: 920px) {
    display: none;
  }
`;

export const ContentLogin = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 15%;
    height: 15%;
  }
  h2 {
    color: #40444c;
    text-align: center;
    font-family: Poppins;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 2.25rem */
    margin-top: 4rem;
    margin-bottom: 2rem;
  }

  .registerText {
    margin-top: 1.5rem;
    color: #6a717e;

    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    a {
      color: ${(props) => props.theme['orange-500']};
    }
  }
  @media (max-width: 768px) {
    display: none;
  }
  @media (max-width: 768px) {
    display: none;
  }
  @media (max-width: 920px) {
    display: none;
  }
`;

export const ContentForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 28.125rem;
  gap: 1rem;

  @media (max-width: 768px) {
    display: none;
  }
  @media (max-width: 768px) {
    display: none;
  }
  @media (max-width: 920px) {
    display: none;
  }
`;
export const ContentInput = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 0.25rem 0.25rem 0rem 0rem;
  border-bottom: 1px solid #40444c;
  padding: 0.25rem 1rem;

  label {
    position: absolute;
    top: 40%;
    left: 1rem;
    transform: translateY(-50%);
    transition: all 0.2s ease;
    color: #6a717e;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    background-color: transparent;
  }

  label::before {
    content: '';
    position: absolute;
    top: 50%;
    left: -0.5rem;
    width: calc(100% + 24rem);
    height: 110%;
    background-color: #fff;
    z-index: -1;
    transform: translateY(-50%);
    transition: all 0.2s ease;
    margin-top: 5%;
  }

  .inputContainer {
    position: relative;
    width: 100%;
    
  }

  input {
    all: unset;
    width: 100%;
    color: #40444c;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    padding: 0; /* Remova o padding lateral */
    margin-left: 0; /* Ajuste a margem esquerda */
  }

  .inputSenha {
    width: 200%;
    line-height: 150%;
  }

  .verSenha {
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }

  span {
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    color: #6a717e;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    pointer-events: none;
    transition: all 0.2s ease;
    opacity: 0;
  }

  input:focus + span,
  input:not(:placeholder-shown) + span {
    opacity: 1;
    background-color: #fff;
  }

  label.active {
    top: -0.5rem;
    left: 0.75rem;
    font-size: 0.75rem;
    color: #ec6643;
    background-color: transparent;
  }

  span.visible {
    opacity: 1;
  }

  &:focus-within {
    border-bottom: 2px solid #ec6643;

    label {
      color: ${(props) => props.theme['orange-500']};
      font-weight: 400;
    }
  }
  @media (max-width: 768px) {
    display: none;
  }
  @media (max-width: 768px) {
    display: none;
  }
  @media (max-width: 920px) {
    display: none;
  }
`;


export const ButtonRecoveryPassword = styled.button`
  all: unset;
  color: ${(props) => props.theme['orange-500']};
  text-align: right;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  @media (max-width: 768px) {
    display: none;
  }
  @media (max-width: 768px) {
    display: none;
  }
  @media (max-width: 920px) {
    display: none;
  }
`;
export const Button = styled.button`
  all: unset;
  display: flex;
  padding: 0.5rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  align-self: stretch;
  border-radius: 0.5rem;
  background: ${(props) => props.theme['orange-500']};
  color: #f1f2f3;
  height: 2.5rem;

  text-align: center;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.01094rem;
  text-transform: uppercase;

  @media (max-width: 768px) {
    display: none;
  }
  @media (max-width: 768px) {
    display: none;
  }
  @media (max-width: 920px) {
    display: none;
  }
`;

export const MobileContent = styled.div`
  display: none;
  
  @media (max-width: 480px) {
    display: flex;
    background: #ec6643;
    width: auto;
    height: 100vh;
    h4{
      color: #fff;
      text-align: center;
      font-family: Poppins;
      font-size: 3rem;
      font-style: normal;
      font-weight: 600;
      padding: 6rem 1rem 1rem 2rem;
      
    }
    p{
      color: #fff;
      text-align: center;
      font-family: Poppins;
      font-size: 1.75rem;
      font-style: normal;
      font-weight: 600;
      padding: 6rem 1rem 1rem 2rem;
    }
    .images {
      display: flex;
      flex-direction: column;
      width: auto;
      justify-content: center; /* Centraliza no eixo principal */
      align-items: center;    /* Centraliza no eixo cruzado */
      align-self: stretch;
      gap: 1rem;
    }
  }
  
  @media (max-width: 768px) {
    display: flex;
    background: #ec6643
    width: auto;
    height: 100vh;
    justify-content: center;
    align-items: center;
    
    h4{
      text-align: center;
      font-family: Poppins;
      font-size: 2rem;
      font-style: normal;
      font-weight: 600;
      padding: 6rem 1rem 1rem 2rem;
      
    }
    p{
      color: #fff;
      text-align: center;
      font-family: Poppins;
      font-size: 1.75rem;
      font-style: normal;
      font-weight: 600;
      padding: 6rem 1rem 1rem 2rem;
    }
    .images {
      display: flex;
      flex-direction: column;
      width: auto;
      justify-content: center; /* Centraliza no eixo principal */
      align-items: center;    /* Centraliza no eixo cruzado */
      align-self: stretch;
      gap: 1rem;
    }
  
  @media (max-width: 920px) {
    display: flex;
    background: #ec6643
    width: auto;
    height: 100vh;
    justify-content: center;
    align-items: center;
    
    h4{
      text-align: center;
      font-family: Poppins;
      font-size: 2rem;
      font-style: normal;
      font-weight: 600;
      padding: 6rem 1rem 1rem 2rem;
      
    }
    p{
      color: #000;
      text-align: center;
      font-family: Poppins;
      font-size: 1.75rem;
      font-style: normal;
      font-weight: 600;
      padding: 6rem 1rem 1rem 2rem;
    }
    .images {
      display: flex;
      flex-direction: column;
      width: auto;
      justify-content: center; /* Centraliza no eixo principal */
      align-items: center;    /* Centraliza no eixo cruzado */
      align-self: stretch;
      gap: 1rem;
    }
  }
`;
