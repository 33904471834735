import axios from 'axios';
import moment from 'moment';
import { ChartBar, ChartLine } from '@phosphor-icons/react';
import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { financeRoutes } from '../../../../../utils/RoutesApi';
import { transformMaskCurrency } from '../../../../../utils/transformMaskCurrency';
import { ButtonSelectGraphicType, ContentTextModal } from '../../../styles';
import { CardContentData, ContentDataHeader } from './styles';
import { maskValueCards } from '../../../../ResumoAcao/components/maskValueCards';

interface IPropsDividendos {
  CNPJ: string;
  codigo_fii: string;
  data_base: string;
  data_pagamento: string;
  dy: number;
  rendimento: number;
}

interface IPropsGetDividendos {
  slug: string;

  data: {
    value: number;
    label: string;
  }[];
  typeValue?: 'money' | 'percent' | 'noMask';
  title: string;
  highestValue: { value: number }[];
  normalMoney?: boolean;
}

export function GraphicDividendos({
  slug,
  title,
  data,
  typeValue = 'money',
  highestValue = [],
  normalMoney = false,
}: // historyData = [],
IPropsGetDividendos) {
  const [typeGraphic, setTypeGraphic] = useState<'area' | 'bar'>('area');
  // @ts-ignore
  const newData = data.filter((item) => !Number.isNaN(item.value));

  const defineChartCollor = (arr: number[]) => {
    const ok = '#52C41A';
    const danger = '#e52828';

    if (arr[arr.length - 1] > arr[0]) {
      return ok;
    }
    return danger;
  };

  //@ts-ignore
  data = data.sort((a, b) => {
    if (a.label < b.label) {
      return -1;
    }
    return true;
  });
  const graphicData = {
    series: [
      {
        name: title,
        data: newData.map((item) => Number(item.value)),
      },
    ],
    options: {
      chart: {
        stacked: false,
        height: 350,
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: true,
        },
        toolbar: {
          autoSelected: 'zoom',
        },
      },
      labels: data.map((item) => item.label),
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
      },
      colors: [defineChartCollor(data.map((item) => item.value))],

      yaxis: {
        // min: 0,
        // max: highestValue[0].value * 1.5,
        // tickAmount: 5,
        labels: {
          formatter: (val: number) => {
            return typeValue === 'money'
              ? maskValueCards(Number(val.toFixed(2)))
              : typeValue === 'percent'
              ? `${val.toFixed(2)}%`
              : val.toFixed(2);
          },
        },
      },
      xaxis: {
        categories: data.map((val) => moment(val.label).format('MM/YYYY')),
      },

      tooltip: {
        shared: false,
        y: {
          formatter: (val: number) => {
            return typeValue === 'money'
              ? transformMaskCurrency(val)
              : typeValue === 'percent'
              ? `${val.toFixed(2)}%`
              : val.toFixed(2);
          },
        },
      },
      responsive: [
        {
          breakpoint: 1200,
          options: {
            chart: {
              width: '700px',
            },
          },
        },
        {
          breakpoint: 992,
          options: {
            chart: {
              width: '400px',
            },
            xaxis: {
              tickAmount: 7,
            },
          },
        },
        {
          breakpoint: 480,
          options: {
            chart: {
              width: '300px',
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    },
  };
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        width: '100%',
        alignItems: 'start',
      }}
    >
      <ContentTextModal>
        <h1>{title}</h1>
        <ContentDataHeader>
          <CardContentData>
            <p>Média: </p>
            <strong>
              {typeValue === 'money'
                ? !normalMoney
                  ? maskValueCards(
                      Number(
                        (
                          data.reduce((acc, rendimento) => {
                            return acc + rendimento.value;
                          }, 0) / data.length
                        ).toFixed(0)
                      )
                    )
                  : transformMaskCurrency(
                      data.reduce((acc, rendimento) => {
                        return acc + rendimento.value;
                      }, 0) / data.length
                    )
                : typeValue === 'percent'
                ? `${(
                    data.reduce((acc, rendimento) => {
                      return acc + rendimento.value;
                    }, 0) / data.length
                  ).toFixed(2)}%`
                : (
                    data.reduce((acc, rendimento) => {
                      return acc + rendimento.value;
                    }, 0) / data.length
                  ).toFixed(2)}
            </strong>
          </CardContentData>
          <CardContentData>
            <p>Atual: </p>
            <strong>
              {typeValue === 'money'
                ? !normalMoney
                  ? maskValueCards(
                      Number(data[data.length - 1].value.toFixed(0))
                    )
                  : transformMaskCurrency(data[data.length - 1].value)
                : typeValue === 'percent'
                ? `${data[data.length - 1].value.toFixed(2)}%`
                : data[data.length - 1].value.toFixed(2)}
            </strong>
          </CardContentData>

          <CardContentData>
            <p>Maior valor: </p>
            <strong>
              {typeValue === 'money'
                ? !normalMoney
                  ? maskValueCards(Number(highestValue[0].value.toFixed(0)))
                  : transformMaskCurrency(highestValue[0].value)
                : typeValue === 'percent'
                ? `${highestValue[0].value.toFixed(2)}%`
                : highestValue[0].value.toFixed(2)}
            </strong>
          </CardContentData>

          <CardContentData>
            <p>Menor valor: </p>
            <strong>
              {typeValue === 'money'
                ? !normalMoney
                  ? maskValueCards(
                      Number(
                        highestValue[highestValue.length - 1].value.toFixed(0)
                      )
                    )
                  : transformMaskCurrency(
                      highestValue[highestValue.length - 1].value
                    )
                : typeValue === 'percent'
                ? `${highestValue[highestValue.length - 1].value.toFixed(2)}%`
                : highestValue[highestValue.length - 1].value.toFixed(2)}
            </strong>
          </CardContentData>

          <ButtonSelectGraphicType
            //@ts-ignore
            selected={typeGraphic === 'area'}
            onClick={() => setTypeGraphic('area')}
          >
            <ChartLine size={24} color="#EC6643" />
          </ButtonSelectGraphicType>
          <ButtonSelectGraphicType
            //@ts-ignore
            selected={typeGraphic === 'bar'}
            onClick={() => setTypeGraphic('bar')}
          >
            <ChartBar size={24} color="#EC6643" />
          </ButtonSelectGraphicType>
        </ContentDataHeader>
      </ContentTextModal>
      <ReactApexChart
        //@ts-ignore
        options={graphicData.options}
        series={graphicData.series}
        type={typeGraphic === 'area' ? 'area' : 'bar'}
        height={350}
        width={1000}
      />
    </div>
  );
}
