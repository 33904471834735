import ScrollContainer from 'react-indiana-drag-scroll';
import styled from 'styled-components';

export const MainCardContainer = styled.main`
  display: flex;
  flex-direction: column;
  margin-top: 6%;
  width: 100%;
  padding: 1.5rem;
  background: ${(props) => props.theme.white};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;

  .backButton {
    all: unset;
    line-height: 0;
    color: ${(props) => props.theme['gray-300']};
    font-size: 1.5rem;
    font-weight: 500;
    cursor: pointer;
    margin-bottom: 1.5rem;
  }

  @media (max-width: 480px) {
    padding: 0.5rem;
  }
  ::-webkit-scrollbar {
    display: none !important;
  }
`;

export const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  height: 15.25rem;
  max-width: 66.1875rem;
  position: relative;
  gap: 1rem;

  @media (max-width: 1089px) {
    flex-wrap: wrap;
    height: auto;
  }
`;

export const ColMainCard = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  h1 {
    margin: 0;
    font-weight: 700;
    font-size: 1rem;
    color: ${(props) => props.theme['gray-700']};
  }

  .contentAssetsCompany {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    margin-top: 0.25rem;

    li {
      color: ${(props) => props.theme['gray-700']};
      font-weight: 400;
      color: ${(props) => props.theme.white};
      padding: 0.50rem 0.75rem;
      background: #EC6643;
      border-radius: 900px;
      font-size: 1.00rem;
    }
  }

  .rowContentMainCard {
    display: flex;
    // flex-wrap: wrap;
    gap: 3.125rem;
    margin: 0;
    width: 100%;
    ul {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 1.5rem;
      max-height: 15.25rem;

      li {
        display: flex;
        flex-direction: column;
        gap: 3px;

        span {
          font-size: 1rem;
          font-weight: 400;
          color: ${(props) => props.theme['gray-700']};
          font-style: normal;
          line-height: 1.5rem;
        }
        strong {
          line-height: 1.5rem;
          font-weight: 700;
          font-size: 0.875rem;
          color: ${(props) => props.theme['gray-700']};
          font-style: normal;
        }

        @media (max-width: 480px) {
          span {
            font-size: 0.675rem;
          }
          strong {
            font-size: 0.75rem;
          }
        }
      }
    }

    @media (max-width: 1089px) {
      gap: 1rem;
    }
    @media (max-width: 908px) {
      justify-content: space-between;
      width: 100%;
      margin-top: 1rem;
    }
  }

  .backButton {
    all: unset;
    line-height: 0;
    color: ${(props) => props.theme['gray-700']};
    font-size: 1.25rem;
    cursor: pointer;
  }
  @media (max-width: 908px) {
    width: 100%;
  }
`;

export const ContentLogoCompany = styled.div`
  display: flex;
  height: 100%;
  align-items: center;

  img {
    width: 8.75rem;
  }
  @media (max-width: 480px) {
    margin-top: 1rem;
  }
`;

export const Percent = styled.div`
  @media (max-width: 1100px) {
    padding-left: 20%;
  }
  display: flex;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 100%;
  // margin-right: 4rem;
  
  h6 {
    position: absolute;
    margin: 0;
  }
`;

export const MoreButtonContainer = styled.div`
  position: absolute;
  cursor: pointer;
  top: 1.5rem;
  right: 1.5rem;
  z-index: 1;
  .nav-link {
    padding: 0 !important;
  }
`;

export const ContentTab = styled(ScrollContainer)`
  display: flex;
  flex-direction: row;

  gap: 1rem;
  margin-bottom: 1rem;

  width: 100%;
`;

interface IPropsTabComponent {
  isFull?: boolean;
}

export const TabComponent = styled.button<IPropsTabComponent>`
  all: unset;
  border-radius: 21px;
  padding: 0.75rem 1.5rem;
  border-bottom: 1px solid #EC6643;
  font-size: 0.875rem;
  font-weight: 500;
  color: #EC6643;
  white-space: nowrap;

  width: ${(props) => props.isFull && '90%'};
  text-align: ${(props) => props.isFull && 'center'};
`;
