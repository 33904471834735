import { useActives } from '../../../../../hooks/useActives';
import { transformMaskCurrency } from '../../../../../utils/transformMaskCurrency';
import { IPropsIncomeAnnual } from '../../@types';
import { Container } from './styles';

interface IPropsAssets {
  data: IPropsIncomeAnnual;
  bonificacao: any;
}

export function AssetsAndRightsInformationActions({
  data,
  bonificacao,
}: IPropsAssets) {
  //@ts-ignore
  const { showActive } = useActives();

  function formatValueForCurrency(value: number): string {
    return transformMaskCurrency(value);
  }

  const getBonificacaoTotal = () => {
    if (!bonificacao) return 0;
    const precoMedio = data.ValorTotalAtual / data.QuantidadeAtual;
    return Math.floor(bonificacao.Quantity) * precoMedio;
  };
  return (
    <Container>
      <span>
        CNPJ: <strong>{showActive ? data.CNPJ : '**.***.***/****-**'}</strong>
      </span>
      <span>
        Discriminação:{' '}
        <strong>
          {showActive ? (
            <>
              {data.tickerSymbol} - {data.NomeEmpresa} - CNPJ: {data.CNPJ} -
              QUANTIDADE:{' '}
              {data.QuantidadeAtual + (Math.floor(bonificacao?.Quantity) || 0)}{' '}
              - ESCRITURADOR: {data.Escriturador}
            </>
          ) : (
            '*******************************************'
          )}
        </strong>
      </span>

      <span>
        Situação anterior:{' '}
        <strong>
          {showActive
            ? formatValueForCurrency(data.ValorTotalAnterior)
            : 'R$ *****'}
        </strong>
      </span>
      {/* {getBonificacaoTotal() ? (
        <span>
          Bonificação{' '}
          <strong>
            {showActive
              ? formatValueForCurrency(getBonificacaoTotal())
              : 'R$ *****'}
          </strong>
        </span>
      ) : (
        <></>
      )} */}
      <span>
        Situação atual:{' '}
        <strong>
          {showActive
            ? formatValueForCurrency(
                data.ValorTotalAtual + getBonificacaoTotal()
              )
            : 'R$ *****'}
        </strong>
      </span>
    </Container>
  );
}
