import { useEffect, useState } from 'react';
import { ButtonDesign } from '../../../../../../components/ButtonDesign';
import { InputComponent } from '../../../../../../components/InputComponent';
import { CDIContainer } from './styles';
import axios from 'axios';
import { baseURL } from '../../../../../../utils/baseURL';
import { administrationController } from '../../../../../../utils/RoutesApi';
import { useForm } from 'react-hook-form';

interface IPropsCDIData {
  data_referencia: Date;
  id: number;
  nome: string;
  orgao: string;
  valor: number | string;
}

export function CDI() {
  const { handleSubmit, register } = useForm();
  const [cdiData, setCdiData] = useState<IPropsCDIData>({} as IPropsCDIData);

  useEffect(() => {
    getIndices();
  }, []);

  async function getIndices() {
    try {
      const response = await axios.get(
        `${baseURL}${administrationController.INDICES}`
      );
      const getCdi = response.data.find(
        (data: IPropsCDIData) => data.nome === 'CDI'
      );
      if (getCdi) {
        setCdiData(getCdi);
      }
    } catch (error) {}
  }

  async function submit(data: { value: number; indice: string }) {
    data.indice = 'CDI';
    data.value = Number(data.value);
    try {
      const response = await axios.post(
        `${baseURL}${administrationController.INDICES}`,
        data
      );
    } catch (error) {
    }
  }

  return (
    // @ts-ignore
    <CDIContainer onSubmit={handleSubmit(submit)}>
      <label htmlFor="cdi">Editar CDI</label>
      <div style={{
        marginTop:'22%',
        height: '0', 
        
        }}>
        <InputComponent
          defaultValue={cdiData.valor}
          type="number"
          step="0.01"
          id="cdi"
          {...register('value')}
          min={0.01}
          style={{marginTop:'0%' }}
        />
      </div>
      <ButtonDesign
        type="submit"
        style={{ width: 'auto', maxWidth: 'none', minWidth: 'none', marginTop: '-18%', }}
      >
        Salvar
      </ButtonDesign>
    </CDIContainer>
  );
}
