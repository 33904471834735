import axios from 'axios';
import React, { createContext, useEffect, useState } from 'react';
import { financeRoutes, walletRoutes } from '../../../../utils/RoutesApi';
import {
  IPropsActiveWallet,
  IPropsGeneralActiveWallet,
  IPropsUserWallet,
} from '../@types';

interface IPropsActivesProvider {
  children: React.ReactNode;
}

interface IPropsActivesContextWallet {
  fullWallet: IPropsUserWallet;
  setFullWallet: (wallet: IPropsUserWallet) => void;
  setSelectedBrokerage: (brokerage: string) => void;
  selectedBrokerage: string;
  updateWallet: () => void;
  totalInvestments: string;
  totalReserve: string;
  othersFii: any[];
  othersAction: any[];
  updateStrategyRule: () => void;
  typeWallet: boolean;
}

export const ActivesContextWallet = createContext<IPropsActivesContextWallet>({
  fullWallet: {} as IPropsUserWallet,
  setFullWallet: () => {},
  selectedBrokerage: '',
  setSelectedBrokerage: () => {},
  updateWallet: () => {},
  totalInvestments: 'R$ 0',
  totalReserve: 'R$ 0',
  othersFii: [],
  othersAction: [],
  updateStrategyRule: () => {},
  typeWallet: false,
});

export const ActivesProviderWallet = ({ children }: IPropsActivesProvider) => {
  //@ts-ignore
  const [fullWallet, setFullWallet] = useState<IPropsUserWallet>(
    {} as IPropsUserWallet
  );
  const [typeWallet, setTypeWallet] = useState<any>(null);
  const [selectedBrokerage, setSelectedBrokerage] = useState('');
  const [activesReserve, setActivesReserve] = useState<
    IPropsGeneralActiveWallet[]
  >([]);
  const [othersFii, setOthersFii] = useState([]);
  const [othersAction, setOthersAction] = useState([]);

  useEffect(() => {
    if (selectedBrokerage !== '') {
      getUserWallet({ corretora: selectedBrokerage });
    } else {
      getUserWallet();
    }
  }, [selectedBrokerage, typeWallet]);

  useEffect(() => {
    if (typeWallet === null) {
      getStrategyRule();
    }
  }, []);

  async function updateStrategyRule() {
    const editRule = !typeWallet;
    const data = {
      PorcentagemRecomendada: editRule,
    };
    await axios
      .patch(
        `${process.env.REACT_APP_API_URL}${walletRoutes.WALLET_DEFINED_RULE_STRATEGY}`,
        data
      )
      .then((response) => {
        setTypeWallet(response.data.PorcentagemRecomendada);
      })
      .catch((err) => {});
  }

  async function getStrategyRule() {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}${walletRoutes.WALLET_DEFINED_RULE_STRATEGY}`
      )
      .then((response) => {
        setTypeWallet(response.data.PorcentagemRecomendada);
      })
      .catch((err) => {});
  }

  async function getUserWallet(params = {}) {
    console.log('Iniciando getUserWallet com params:', params);

    await axios
      .get(
        `${process.env.REACT_APP_API_URL}${financeRoutes.FINANCE_POSITION}`,
        {
          params,
        }
      )
      .then(function (result) {
        console.log('Resultado da API:', result.data);
        setActivesReserve(result.data.positionsAll);

        // Inicialize arrays vazios por padrão
        let newDataArrayAction = [];
        let newDataArrayFii = [];

        // Verifique se 'positionAcao' é um array antes de usar 'map'
        if (Array.isArray(result.data.positionAcao)) {
          console.log('positionAcao é um array:', result.data.positionAcao);

          newDataArrayAction = result.data.positionAcao.map(
            (item: IPropsActiveWallet) => {
              const isExceptionAction = item.Exception.find(
                (excp: any) => excp.type === 'action'
              );
              if (!isExceptionAction) {
                item.ValorExp = result.data.objetivoAcao;
                return item;
              }
              return item;
            }
          );

          newDataArrayAction = newDataArrayAction.filter(
            (item: IPropsActiveWallet) => !item.reservadeemergencia
          );
        } else {
          console.error('positionAcao is not an array or is undefined');
        }

        // Verifique se 'positionFII' é um array antes de usar 'map'
        if (Array.isArray(result.data.positionFII)) {
          console.log('positionFII é um array:', result.data.positionFII);

          newDataArrayFii = result.data.positionFII.map(
            (item: IPropsActiveWallet) => {
              if (!item.Exception) {
                item.ValorExp = result.data.objetivoFII;
                return item;
              }
              return item;
            }
          );

          newDataArrayFii = newDataArrayFii.filter(
            (item: IPropsActiveWallet) => !item.reservadeemergencia
          );
        } else {
          console.error('positionFII is not an array or is undefined');
        }

        let allActivesPosition = [];
        if (Array.isArray(result.data.positionsAll)) {
          console.log('positionsAll é um array:', result.data.positionsAll);

          allActivesPosition = result.data.positionsAll.map((item: any) => {
            const isExceptionAll = item.Exception.find(
              (excp: any) => excp.type === 'all'
            );
            const custo =
              Number(item.PrecoMedio.toFixed(2)) * item.equitiesQuantity;
            item.lucro = Number(item.updateValue.toFixed(2)) - custo;
            if (typeWallet) {
              item.ValorExp = item.porcentagem_recomendada;
              item.gap = item.ValorExp - item.percent;

              return item;
            } else if (!isExceptionAll) {
              if (item.productTypeName === 'acao') {
                item.ValorExp = result.data.objetivoAll;
                item.gap = item.ValorExp - item.percent;

                return item;
              } else if (item.productTypeName === 'fii') {
                item.ValorExp = result.data.objetivoAll;
                item.gap = item.ValorExp - item.percent;

                return item;
              } else {
                item.ValorExp = result.data.objetivoAll;
                item.gap = item.ValorExp - item.percent;

                return item;
              }
            }
            item.ValorExp = isExceptionAll.value;
            item.gap = item.ValorExp - item.percent;
            return item;
          });

          allActivesPosition = allActivesPosition.filter(
            (item: any) => !item.reservadeemergencia
          );
        } else {
          console.error('positionsAll is not an array or is undefined');
        }

        console.log('Dados finais: ', {
          ...result.data,
          positionFII: newDataArrayFii,
          positionAcao: newDataArrayAction,
          positionsAll: allActivesPosition,
        });

        setFullWallet({
          ...result.data,
          positionFII: newDataArrayFii,
          positionAcao: newDataArrayAction,
          positionsAll: allActivesPosition,
        });

        if (newDataArrayFii.length > 0) {
          let fiis = newDataArrayFii.filter(
            (item: IPropsActiveWallet) => !item.reservadeemergencia
          );
          fiis = fiis.filter((item: IPropsActiveWallet) => item.percent > 0.0);
          fiis = fiis.filter((item: IPropsActiveWallet) => item.percent < 1);
          fiis = fiis.map((item: IPropsActiveWallet) => item.percent);

          let largerThanOne = newDataArrayFii.filter(
            (item: IPropsActiveWallet) => !item.reservadeemergencia
          );

          largerThanOne = largerThanOne.filter(
            (item: IPropsActiveWallet) => item.percent >= 1
          );
          if (fiis.length > 0) {
            //@ts-ignore
            fiis = fiis.reduce((a, b) => a + b);

            fiis = [
              ...largerThanOne,
              {
                percent: fiis,
                tickerSymbol: 'Outros',
                Exception: false,
                ValorExp: 0,
                aportarQtde: 0,
                closingPrice: 0,
                corporationName: '',
                equitiesQuantity: 0,
                novoupdateValue: 0,
                updateValue: 0,
              },
            ];

            setOthersFii(fiis);
          } else {
            setOthersFii(largerThanOne);
          }
        }

        if (newDataArrayAction.length > 0) {
          let actions = newDataArrayAction.filter(
            (item: IPropsActiveWallet) => !item.reservadeemergencia
          );

          actions = actions.filter(
            (item: IPropsActiveWallet) => item.percent > 0.0
          );

          actions = actions.filter(
            (item: IPropsActiveWallet) => item.percent < 1
          );

          actions = actions.map((item: IPropsActiveWallet) => item.percent);
          let largerThanOne = newDataArrayAction.filter(
            (item: IPropsActiveWallet) => !item.reservadeemergencia
          );

          largerThanOne = largerThanOne.filter(
            (item: IPropsActiveWallet) => item.percent >= 1
          );
          if (actions.length > 0) {
            //@ts-ignore
            actions = actions.reduce((a, b) => a + b);

            actions = [
              ...largerThanOne,
              {
                percent: actions,
                tickerSymbol: 'Outros',
                Exception: false,
                ValorExp: 0,
                aportarQtde: 0,
                closingPrice: 0,
                corporationName: '',
                equitiesQuantity: 0,
                novoupdateValue: 0,
                updateValue: 0,
              },
            ];

            setOthersAction(actions);
          } else {
            setOthersAction(largerThanOne);
          }
        }
      })
      .catch(function (error) {
        console.error('Erro ao obter dados da API:', error);
      });
  }

  function updateWallet() {
    getUserWallet();
  }

  const totalInvestments = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(
    fullWallet.positionsAll
      ? fullWallet.positionsAll.reduce(
          (acc: number, position: IPropsGeneralActiveWallet) => {
            return acc + position.updateValue;
          },
          0
        )
      : 0
  );
  const totalReserve = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(
    activesReserve
      ? activesReserve
          .filter((item) => item.reservadeemergencia)
          .reduce((acc: number, position: IPropsGeneralActiveWallet) => {
            return acc + position.updateValue;
          }, 0)
      : 0
  );

  return (
    <ActivesContextWallet.Provider
      value={{
        fullWallet,
        setSelectedBrokerage,
        selectedBrokerage,
        updateWallet,
        setFullWallet,
        totalInvestments,
        totalReserve,
        othersFii,
        othersAction,
        updateStrategyRule,
        typeWallet,
      }}
    >
      {children}
    </ActivesContextWallet.Provider>
  );
};
