import axios from 'axios';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import moment from 'moment';
import { Switch } from 'antd';
import ReactInputMask from 'react-input-mask';

import { useTitleAndUser } from '../../../../hooks/userTitle';
import { ButtonComponent } from '../../../../components/ButtonComponent';
import { administrationController } from '../../../../utils/RoutesApi';
import { handleToast } from '../../../../components/Toast';
import {
  IPropsPlanData,
  IPropsPlanIRData,
  IPropsUserData,
  defaultValuePlanData,
  defaultValuePlanIr,
  userDefault,
} from './user';
import { NewPasswordForm } from './components/NewPasswordForm';
import { ResetTrialForm } from './components/ResetTrialForm';
import { transformMaskCurrency } from '../../../../utils/transformMaskCurrency';
import {
  ContentCardsDataUser,
  ContentDataUser,
  SearchContent,
  UserAdminContainer,
} from './styles';
import { ModalDesigner } from '../../../../components/ModalDesigner';

const baseUrl = process.env.REACT_APP_API_URL;

interface IPropsTypeSearch {
  type: 'email' | 'cpf';
  checked: boolean;
}

export function UserPainel() {
  //@ts-ignore
  const { setTitle } = useTitleAndUser();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();
  const [dataUser, setDataUser] = useState<IPropsUserData>(userDefault);
  const [dataPlanUser, setDataPlanUser] =
    useState<IPropsPlanData>(defaultValuePlanData);
  const [dataPlanIRUser, setDataPlanIRUser] =
    useState<IPropsPlanIRData>(defaultValuePlanIr);
  const [showModalNewPassword, setShowModalNewPassword] = useState(false);
  const [showModalResetTrial, setShowModalResetTrial] = useState(false);
  const [typeSearch, setTypeSearch] = useState<IPropsTypeSearch>({
    checked: false,
    type: 'email',
  });
  const [editUserData, setEditUserData] = useState(false);

  useEffect(() => {
    setTitle('Gestão do usuário');
  }, []);

  async function handleSearchUser(data: any) {
    setDataPlanIRUser(defaultValuePlanIr)
    setDataPlanUser(defaultValuePlanData)
    setDataUser(userDefault)
    const { email, cpf } = data;
    let search;
    if (typeSearch.type === 'email') {
      search = email;
    } else {
      search = cpf;
    }
    await axios
      .get(`${baseUrl}${administrationController.GET_DATA_USER(search)}`)
      .then((response) => {
        setDataUser(response.data);
      })
      .catch((err) => {
        handleToast(true, err.response.data.detail);
      });
    await axios
      .get(`${baseUrl}${administrationController.GET_DATA_PLAN_USER(search)}`)
      .then((response) => {
        setDataPlanUser(response.data);
      })
      .catch((err) => {});
    await axios
      .get(
        `${baseUrl}${administrationController.GET_DATA_PLAN_IR_USER(search)}`
      )
      .then((response) => {
        setDataPlanIRUser(response.data);
      })
      .catch((err) => {});
  }

  async function editDataUser(data: any) {
    const { cpfEdit, emailEdit } = data;
    const cpfValidation = cpfEdit.replace('_', '');
    if (cpfValidation.length !== 14) {
      return handleToast(true, 'CPF invalido');
    }
    try {
      if (cpfEdit !== dataUser.cpf) {
        await axios.patch(
          `${baseUrl}${administrationController.UPDATE_EMAIL_CPF_USER(
            dataUser.email
          )}`,

          {
            cpf: cpfEdit,
          }
        );
      }
      if (emailEdit !== dataUser.email) {
        await axios.patch(
          `${baseUrl}${administrationController.UPDATE_EMAIL_CPF_USER(
            dataUser.email
          )}`,

          {
            email: emailEdit,
          }
        );
      }

      setDataUser({
        ...dataUser,
        email: emailEdit,
        cpf: cpfEdit,
      });

      setEditUserData(false);

      handleToast(false, 'Dados editados com sucesso');
    } catch (err) {
      handleToast(true, 'Erro ao editar dados');
    }
  }

  return (
    <UserAdminContainer>
      <SearchContent onSubmit={handleSubmit(handleSearchUser)}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '0.5rem',
          }}
        >
          <strong style={{ color: 'black', margin: 0 }}>
            Buscar por EMAIL
          </strong>
          <Switch
            size="small"
            checked={typeSearch.checked}
            //@ts-ignore
            size="default"
            onClick={() => {
              if (typeSearch.checked) {
                return setTypeSearch({
                  checked: false,
                  type: 'email',
                });
              } else {
                return setTypeSearch({
                  checked: true,
                  type: 'cpf',
                });
              }
            }}
          />
          <strong style={{ color: 'black', margin: 0 }}>Buscar por CPF</strong>
        </div>
        {typeSearch.checked ? (
          <label>
            Buscar usuário
            <ReactInputMask
              mask="999.999.999-99"
              {...register('cpf')}
              placeholder="123.456.789-00"
            />
          </label>
        ) : (
          <label>
            Buscar usuário
            <input
              type="email"
              {...register('email')}
              placeholder="johndoe@example.com"
            />
          </label>
        )}
        <div>
          <ButtonComponent type="submit" disabled={isSubmitting}>
            Buscar usuário
          </ButtonComponent>
        </div>
      </SearchContent>
      <ContentCardsDataUser>
        {dataUser.Name !== '' && (
          <ContentDataUser onSubmit={handleSubmit(editDataUser)}>
            <h1>Dados do usuário</h1>
            <p>
              Nome do usuário: <strong>{dataUser.Name}</strong>
            </p>
            <p>
              Celular:{' '}
              <a
                href={`https://api.whatsapp.com/send?phone=55${dataUser.ddd}${dataUser.telefone}&text=Ol%C3%A1,%20sou%20do%20suporte%20iBox6.`}
                target="_blank"
              >
                ({dataUser.ddd}) {dataUser.telefone}
              </a>
            </p>
            <p>
              E-mail do usuário:
              {editUserData ? (
                <input
                  className="inputUserData"
                  defaultValue={dataUser.email}
                  {...register('emailEdit')}
                  type="email"
                />
              ) : (
                <strong>{dataUser.email}</strong>
              )}
            </p>
            <p>
              CPF:{' '}
              {editUserData ? (
                <ReactInputMask
                  mask="999.999.999-99"
                  className="inputUserData"
                  defaultValue={dataUser.cpf}
                  {...register('cpfEdit')}
                />
              ) : (
                <strong>{dataUser.cpf}</strong>
              )}
            </p>
            <p>
              Data de criação:{' '}
              <strong>
                {moment(dataUser.created).format('L')} (
                {moment(new Date()).diff(dataUser.created, 'days')})
              </strong>
            </p>

            <p>
              utm campaign: <strong>{dataUser.utm_campaign}</strong>
            </p>
            <p>
              utm medium: <strong>{dataUser.utm_medium}</strong>
            </p>
            <p>
              utm source: <strong>{dataUser.utm_source}</strong>
            </p>
            <div className="buttonContent">
              {!editUserData ? (
                <>
                  <ButtonComponent
                    type="button"
                    onClick={() => setShowModalNewPassword(true)}
                  >
                    Mudar senha
                  </ButtonComponent>
                  <ButtonComponent
                    type="button"
                    onClick={() => setShowModalResetTrial(true)}
                  >
                    Resetar Trial
                  </ButtonComponent>
                  <ButtonComponent
                    type="button"
                    onClick={() => setEditUserData(true)}
                  >
                    Editar dados
                  </ButtonComponent>
                </>
              ) : (
                <ButtonComponent
                  type="submit"
                  // onClick={() => setEditUserData(false)}
                >
                  Salvar
                </ButtonComponent>
              )}
            </div>
          </ContentDataUser>
        )}
        {dataPlanUser.NomePlano !== '' && (
          <ContentDataUser>
            <h1>Plano iBox6</h1>

            <p>
              Plano do usuário: <strong>{dataPlanUser.NomePlano}</strong>
            </p>
            <p>
              Status:{' '}
              <strong>
                {dataPlanUser.Status === 'canceled'
                  ? 'Cancelado'
                  : dataPlanUser.Status === 'paid'
                  ? 'Pago'
                  : dataPlanUser.Status === 'bonus'
                  ? 'Bônus'
                  : dataPlanUser.Status === 'pago'
                  ? 'Pago'
                  : 'Pendente'}
              </strong>
            </p>


            <p>
              Valor do plano:{' '}
              <strong>{transformMaskCurrency(dataPlanUser.Valor)}</strong>
            </p>
            <p>
              Data de assinatura:{' '}
              <strong>
                {moment(dataPlanUser.currentPeriodStart).format('L')}
              </strong>
            </p>
            <p>
              Data de vencimento:{' '}
              <strong>
                {moment(dataPlanUser.currentPeriodEnd).format('L')}
              </strong>
            </p>
            <p>
              Método de pagamento:{' '}
              <strong>
                {dataPlanUser.paymentMethod === 'credit_card'
                  ? 'Cartão'
                  : 'Boleto'}
              </strong>
            </p>
            <h1>Plano IR </h1>
            <p>
              Plano do usuário: <strong>{dataPlanIRUser.NomePlano}</strong>
            </p>
            <p>
              Status:{' '}
              <strong>
                {dataPlanIRUser.Status === 'canceled'
                  ? 'Cancelado'
                  : dataPlanIRUser.Status === 'paid'
                  ? 'Pago'
                  : dataPlanIRUser.Status === 'bonus'
                  ? 'Bônus'
                  : dataPlanIRUser.Status === 'pago'
                  ? 'Pago'
                  : 'Pendente'}
              </strong>
            </p>

            <p>
              Valor do plano:{' '}
              <strong>{transformMaskCurrency(dataPlanIRUser.Valor)}</strong>
            </p>
            <p>
              Data de assinatura:{' '}
              <strong>
                {moment(dataPlanIRUser.currentPeriodStart).format('L')}
              </strong>
            </p>
            <p>
              Data de vencimento:{' '}
              <strong>
                {moment(dataPlanIRUser.currentPeriodEnd).format('L')}
              </strong>
            </p>
            <p>
              Método de pagamento:{' '}
              <strong>
                {dataPlanIRUser.paymentMethod === 'credit_card'
                  ? 'Cartão'
                  : 'Boleto'}
              </strong>
            </p>
          </ContentDataUser>
        )}
      </ContentCardsDataUser>

      <ModalDesigner
        id="newpasswordreset"
        isOpen={showModalNewPassword}
        setState={setShowModalNewPassword}
        title="Trocar senha"
        size="sm"
      >
        <NewPasswordForm
          email={dataUser.email}
          onCloseModal={() => setShowModalNewPassword(false)}
        />
      </ModalDesigner>

      <ModalDesigner
        id="resetTrial"
        isOpen={showModalResetTrial}
        setState={setShowModalResetTrial}
        title="Resetar Trial"
        size="sm"
      >
        <ResetTrialForm
          email={dataUser.email}
          onCloseModal={() => setShowModalResetTrial(false)}
        />
      </ModalDesigner>
    </UserAdminContainer>
  );
}
