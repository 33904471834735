import Select from 'react-select';
import {
  AsideContentFilters,
  AsideHeader,
  ButtonShowMoreFilters,
  ContentInputFilters,
  ContentInputShowFilters,
  FiltersStyles,
  ContentInputShowFilters2,
  ShowMoreFilters,
} from './styles';
import { useEffect, useState } from 'react';
import { IPropsFilters } from '../SearchFiltersListRankAction';
import axios from 'axios';
import { baseURL } from '../../../../../utils/baseURL';
import { financeRoutes } from '../../../../../utils/RoutesApi';
import { Slider } from 'antd';
import { CaretDown, CaretUp } from '@phosphor-icons/react';
import { SliderMarks } from 'antd/es/slider';
import filtros from '../../../../../assets/IconFilters.png';
import { editValueFilterDivida } from './components/editValueFilterDivida';
import { ClearFilterButton } from '../../../../../components/ClearFilterButton/styles';
import {HeaderContainer2} from '../../../../../Header';

interface IPropsSearchFilterListRankAction {
  setSearchFilters: Function;
  searchFilters: IPropsFilters;
}
interface IPropsSelectedArr {
  value: string;
  label: string;
}

export function AsideFiltersAction({
  searchFilters,
  setSearchFilters,
}: IPropsSearchFilterListRankAction) {
  const [showAllFilters, setShowAllFilters] = useState(false);
  const [segmentos, setSegmentos] = useState<IPropsSelectedArr[]>([
    {
      value: '',
      label: 'Todos',
    },
  ]);
  const [setores, setSetores] = useState<IPropsSelectedArr[]>([
    {
      value: '',
      label: 'Todos',
    },
  ]);
  const [subSetores, setSubSetores] = useState<IPropsSelectedArr[]>([
    {
      value: '',
      label: 'Todos',
    },
  ]);
  const [dyValueFilter, setDyValueFilter] = useState<number>(
    searchFilters.dividendos
  );
  // const [roeFilter, setRoeFilter] = useState<number>(searchFilters.roe);
  const [ebitdaFilter, setEbitdaFilter] = useState<number>(() => {
    if (searchFilters.ebitda === 10000000) {
      return 32;
    }
    if (searchFilters.ebitda === 50000000) {
      return 64;
    }
    if (searchFilters.ebitda === 1000000000) {
      return 96;
    }
    if (searchFilters.ebitda === 10000000000) {
      return 128;
    }
    if (searchFilters.ebitda === 100000000000) {
      return 160;
    }
    return 0;
  });
  const [dividaLiquidaFilter, setDividaLiquidaFilter] = useState(() => {
    if (searchFilters.dividaliquida === -50000000) {
      return 0;
    }
    if (searchFilters.dividaliquida === -10000000) {
      return 25;
    }
    if (searchFilters.dividaliquida === 0) {
      return 50;
    }
    if (searchFilters.dividaliquida === 10000000) {
      return 75;
    }

    if (searchFilters.dividaliquida === 30000000) {
      return 100;
    }

    return 0;
  });
  const [dividaEbitdaFilter, setDividaEbitdaFilter] = useState<number>(
    searchFilters.dividaebitda
  );
  const [lucroFilter, setLucroFilter] = useState<number>(() => {
    if (searchFilters.lucroliquido === 10000000) {
      return 32;
    }

    if (searchFilters.lucroliquido === 50000000) {
      return 64;
    }
    if (searchFilters.lucroliquido === 1000000000) {
      return 96;
    }
    if (searchFilters.lucroliquido === 10000000000) {
      return 128;
    }

    if (searchFilters.lucroliquido === 100000000000) {
      return 160;
    }

    return 0;
  });
  const [selectedValues, setSelectedValues] = useState({
    segmento: searchFilters.segmento,
    segmento_listagem: searchFilters.segmento_listagem,
    setor: searchFilters.setor,
    subsetor: searchFilters.subsetor,
  });

  async function filtersSectorsActions() {
    await axios
      .get(`${baseURL}${financeRoutes.FILTERS_SECTORS_ACTIONS}`)
      .then((response) => {
        const removeDuplicate = new Set();

        const segmentosList = response.data.segmentos
          //@ts-ignore
          .map((item) => {
            return {
              value: item.segmento,
              label: `${item.segmento
                .substring(0, 1)
                .toUpperCase()}${item.segmento.substring(1).toLowerCase()}`,
            };
          })
          .sort((a: any, b: any) => a.label.localeCompare(b.label));
        const setoresList = response.data.setores
          //@ts-ignore
          .map((item) => {
            return {
              value: item.setor,
              label: `${item.setor.substring(0, 1).toUpperCase()}${item.setor
                .substring(1)
                .toLowerCase()}`,
            };
          })
          .sort((a: any, b: any) => a.label.localeCompare(b.label));
        const subSetoresList = response.data.sub_setores
          //@ts-ignore
          .map((item) => {
            return {
              value: item.sub_setor,
              label: `${item.sub_setor
                .substring(0, 1)
                .toUpperCase()}${item.sub_setor.substring(1).toLowerCase()}`,
            };
          })
          .sort((a: any, b: any) => a.label.localeCompare(b.label));

        let newSetores = setoresList;
        // @ts-ignore
        newSetores = newSetores.filter((setor) => {
          const duplicatedSector = removeDuplicate.has(
            setor.value.toLowerCase()
          );
          removeDuplicate.add(setor.value.toLowerCase());
          return !duplicatedSector;
        });
        let newSubSetores = subSetoresList;
        // @ts-ignore
        newSubSetores = newSubSetores.filter((setor) => {
          const duplicatedSector = removeDuplicate.has(
            setor.value.toLowerCase()
          );
          removeDuplicate.add(setor.value.toLowerCase());
          return !duplicatedSector;
        });
        let newSegmentos = segmentosList;
        // @ts-ignore
        newSegmentos = newSegmentos.filter((setor) => {
          const duplicatedSector = removeDuplicate.has(
            setor.value.toLowerCase()
          );
          removeDuplicate.add(setor.value.toLowerCase());
          return !duplicatedSector;
        });
        setSubSetores([...subSetores, ...newSubSetores]);
        setSetores([...setores, ...newSetores]);
        setSegmentos([...segmentos, ...newSegmentos]);
      })
      .catch((err) => {});
  }

  useEffect(() => {
    filtersSectorsActions();
  }, []);
  
  const arrSegList = [
    {
      value: '',
      label: 'Todos',
    },
    {
      value: 'N1',
      label: 'Nível 1',
    },
    {
      value: 'N2',
      label: 'Nível 2',
    },
    {
      value: 'NM',
      label: 'Novo mercado',
    },
    {
      value: 'MA',
      label: 'Bovespa mais',
    },
    {
      value: 'Tradicional',
      label: 'Tradicional',
    },
  ];
  const returnSelectValue = (
    id: string,
    arr: {
      value: string;
      label: string;
    }[]
  ) => {
    if (id) {
      let obj = arr?.map((item) => {
        if (item?.value === id) {
          return {
            value: item.value,
            label: item.label,
          };
        }
      });
      return obj;
    } else {
      return null;
    }
  };

  function handleChangeDyFilter(value: number) {
    setDyValueFilter(value);
    setSearchFilters({
      ...searchFilters,
      isFiltered: true,
      dividendos: value,
    });
  }
  function handleChangeDividaEbitdaFilter(value: number) {
    setDividaEbitdaFilter(value);
    setSearchFilters({
      ...searchFilters,
      isFiltered: true,
      dividaebitda: value,
    });
  }
  // function handleChangeRoeFilter(value: number) {
  //   setRoeFilter(value);
  //   setSearchFilters({
  //     ...searchFilters,
  //     isFiltered: true,
  //     roe: value,
  //   });
  // }
  function handleChangeEbitdaFilter(value: number) {
    setEbitdaFilter(value);
    setSearchFilters({
      ...searchFilters,
      isFiltered: true,
      ebitda: editValueFilter(value),
    });
  }

  function editValueFilter(val: number) {
    if (val === 32) {
      return 10000000;
    }

    if (val === 64) {
      return 50000000;
    }
    if (val === 96) {
      return 1000000000;
    }
    if (val === 128) {
      return 10000000000;
    }

    if (val === 160) {
      return 100000000000;
    }
    return 0;
  }

  function handleChangeLucroFilter(value: number) {
    setLucroFilter(value);
    setSearchFilters({
      ...searchFilters,
      isFiltered: true,
      lucroliquido: editValueFilter(value),
    });
  }
  function handleChangeDividaLiquidaFilter(value: number) {
    setDividaLiquidaFilter(value);
    setSearchFilters({
      ...searchFilters,
      isFiltered: true,
      dividaliquida: editValueFilterDivida(value),
    });
  }

  const marksMobile: SliderMarks = {
    0: '0',
    32: '10mi',
    64: '500mi',
    96: '1bi',
    128: '10bi',
    160: {
      style: {
        transform: 'translateX(-110%)',
      },
      label: '100bi',
    },
  };

  const tooltip = {
    formatter: (val: number) => {
      if (val === 0) {
        return `0`;
      }

      if (val === 32) {
        return 'Acima de 10 milhões';
      }

      if (val === 64) {
        return 'Acima de 500 milhões';
      }
      if (val === 96) {
        return 'Acima de 1 bilhão';
      }
      if (val === 128) {
        return 'Acima de 10 bilhões';
      }

      if (val === 160) {
        return 'Acima de 100 bilhões';
      }
    },
  };
  const tooltipDividaLiquida = {
    formatter: (val: number) => {
      if (val === 0) {
        return `Abaixo de -50 milhões`;
      }
      if (val === 25) {
        return 'Abaixo de -10 milhões';
      }

      if (val === 50) {
        return 'Abaixo de 0';
      }
      if (val === 75) {
        return 'Abaixo de 10 milhões';
      }
      if (val === 100) {
        return 'Abaixo de 30 milhões';
      }
    },
  };

  const marksDividaLiquidaMobile: SliderMarks = {
    0: '-50mi',
    25: '-10mi',
    50: '0',
    75: '10mi',

    100: {
      style: {
        transform: 'translateX(-110%)',
      },
      label: '30mi',
    },
  };

  return (
    
      <AsideContentFilters>
        <AsideHeader>
          <span>Filtros</span>

          <ClearFilterButton
            type="button"
            onClick={() => {
              setSearchFilters({
                lucroliquido: 0,
                roe: 0,
                ebitda: 0,
                dividendos: 0,
                dividaliquida: 0,
                dividaebitda: 0,
                cnpjativo: '',
                setor: '',
                segmento: '',
                nome_empresa: '',
                subsetor: '',
                segmento_listagem: '',
                isFiltered: false,
              });
              setDyValueFilter(0);
              setEbitdaFilter(0);
              setDividaLiquidaFilter(50);
              setDividaEbitdaFilter(0);
              setLucroFilter(0);
              setSelectedValues({
                segmento: '',
                segmento_listagem: '',
                setor: '',
                subsetor: '',
              });
            }}
          >
            Limpar filtros
          </ClearFilterButton>
        </AsideHeader>
        <FiltersStyles>
          <ContentInputFilters>
            <label style={{}}>Segmento de listagem</label>
            <Select
              className="select"
              options={arrSegList}
              //@ts-ignore
              onChange={(e) => {
                setSearchFilters({
                  ...searchFilters,
                  // @ts-ignore
                  segmento_listagem: e.value,
                });
                setSelectedValues({
                  ...selectedValues,
                  // @ts-ignore
                  segmento_listagem: e.value,
                });
              }}
              value={returnSelectValue(
                selectedValues.segmento_listagem,
                arrSegList
              )}
              placeholder="Todos"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderTop: state.isFocused ? 'transparent' : 'transparent',
                  borderRight: state.isFocused ? 'transparent' : 'transparent',
                  borderLeft: state.isFocused ? 'transparent' : 'transparent',
                  '&:hover': { 
                    borderTop: 'transparent', // Remove a borda no hover 
                    borderRight: 'transparent', // Remove a borda no hover 
                    borderLeft: 'transparent', // Remove a borda no hover
                    // borderBottom: '2px solid #EC6643' // gabriel aqui está o hover laranja
                  },
                  marginTop: '0',
                  marginLeft: '0',
                  display: 'flex',
                  width: 200,
                  height: 56,
                  padding: '4px 4px 4px 12px',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flex: '1 0 0',
                  borderBottom: '1px solid var(--Gray-gray-600, #6A717E)',
                  background: 'transparent',
                }),
                container: (baseStyles) => ({
                  ...baseStyles,
                  borderRadius: 0,
                  background: 'transparent'
                }),
                input: (baseStyles) => ({
                  ...baseStyles,
                  color: 'none',
                  fontSize: 16,
                  background: 'transparent'
                }),
                placeholder: (base) => ({
                  ...base,
                  background: 'transparent'
                }),
                valueContainer: (base) => ({
                  ...base,
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingTop: 8,
                  paddingBottom: 8,
                  background: 'transparent'
                }),
                indicatorSeparator: () => ({
                  display: 'none',
                }),
                dropdownIndicator: (base) => ({
                  ...base,
                  color: '#808080',
                  background: 'transparent'
                }),
                menu: (base) => ({
                  ...base,
                  marginTop: '0',
                  position: 'absolute',
                  top: 'calc(100% + 10px)',
                  left: '0%',
                  width: '100%', 
                  background: '#FFF', 
                  border: '1px solid #C0C3C9', 
                  borderRadius: '8px', 
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', 
                  zIndex: '2',
                  padding: '10px'
                }),
              }}
            />
          </ContentInputFilters>
          <ContentInputFilters>
            <label style={{marginRight: '0',}}>Segmento</label>
            <Select
              options={segmentos}
              //@ts-ignore
              onChange={(e) => {
                setSearchFilters({
                  ...searchFilters,
                  //@ts-ignore
                  segmento: e.value,
                });
                setSelectedValues({
                  ...selectedValues,
                  // @ts-ignore
                  segmento: e.value,
                });
              }}
              value={returnSelectValue(selectedValues.segmento, segmentos)}
              placeholder="Todos"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderTop: state.isFocused ? 'transparent' : 'transparent',
                  borderRight: state.isFocused ? 'transparent' : 'transparent',
                  borderLeft: state.isFocused ? 'transparent' : 'transparent',
                  '&:hover': { 
                    borderTop: 'transparent', // Remove a borda no hover 
                    borderRight: 'transparent', // Remove a borda no hover 
                    borderLeft: 'transparent', // Remove a borda no hover 
                  },
                  marginTop: '0',
                  marginLeft: '0',
                  display: 'flex',
                  width: 200,
                  height: 56,
                  padding: '4px 4px 4px 12px',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flex: '1 0 0',
                  borderBottom: '1px solid var(--Gray-gray-600, #6A717E)',
                  background: 'transparent',
                }),
                container: (baseStyles) => ({
                  ...baseStyles,
                  borderRadius: 0,
                }),
                input: (baseStyles) => ({
                  ...baseStyles,
                  color: 'none',
                  fontSize: 16,
                }),
                placeholder: (base) => ({
                  ...base,
                }),
                valueContainer: (base) => ({
                  ...base,
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingTop: 8,
                  paddingBottom: 8,
                }),
                indicatorSeparator: () => ({
                  display: 'none',
                }),
                dropdownIndicator: (base) => ({
                  ...base,
                  color: '#808080',
                }),
                menu: (base) => ({
                  ...base,
                  marginTop: '0',
                  position: 'absolute',
                  top: 'calc(100% + 10px)',
                  left: '0',
                  width: '100%', 
                  background: '#FFF', 
                  border: '1px solid #C0C3C9', 
                  borderRadius: '8px', 
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', 
                  zIndex: '2',
                  padding: '10px'
                }),
              }}
            />
          </ContentInputFilters>
          <ContentInputFilters>
            <label style={{marginRight: '0',}}>Setor</label>
            <Select
              className="select"
              options={setores}
              //@ts-ignore
              onChange={(e) => {
                setSearchFilters({
                  ...searchFilters,
                  //@ts-ignore
                  setor: e.value,
                });
                setSelectedValues({
                  ...selectedValues,
                  // @ts-ignore
                  setor: e.value,
                });
              }}
              value={returnSelectValue(selectedValues.setor, setores)}
              placeholder="Todos"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderTop: state.isFocused ? 'transparent' : 'transparent',
                  borderRight: state.isFocused ? 'transparent' : 'transparent',
                  borderLeft: state.isFocused ? 'transparent' : 'transparent',
                  '&:hover': { 
                    borderTop: 'transparent', // Remove a borda no hover 
                    borderRight: 'transparent', // Remove a borda no hover 
                    borderLeft: 'transparent', // Remove a borda no hover 
                  },
                  marginTop: '0',
                  marginLeft: '0',
                  display: 'flex',
                  width: 200,
                  height: 56,
                  padding: '4px 4px 4px 12px',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flex: '1 0 0',
                  borderBottom: '1px solid var(--Gray-gray-600, #6A717E)',
                  background: 'transparent',
                }),
                container: (baseStyles) => ({
                  ...baseStyles,
                  borderRadius: 0,
                  background: 'transparent'
                }),
                input: (baseStyles) => ({
                  ...baseStyles,
                  color: 'none',
                  fontSize: 16,
                  background: 'transparent'
                }),
                placeholder: (base) => ({
                  ...base,
                  background: 'transparent'
                }),
                valueContainer: (base) => ({
                  ...base,
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingTop: 8,
                  paddingBottom: 8,
                  background: 'transparent'
                }),
                indicatorSeparator: () => ({
                  display: 'none',
                }),
                dropdownIndicator: (base) => ({
                  ...base,
                  color: '#808080',
                  background: 'transparent'
                }),
                menu: (base) => ({
                  ...base,
                  marginTop: '0',
                  position: 'absolute',
                  top: 'calc(100% + 10px)',
                  left: '0',
                  width: '100%', 
                  background: '#FFF', 
                  border: '1px solid #C0C3C9', 
                  borderRadius: '8px', 
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', 
                  zIndex: '2',
                  padding: '10px'
                }),
              }}
            />
          </ContentInputFilters>
          <ContentInputFilters>
            <label style={{marginRight: '0',}}>Sub setor</label>
            <Select
              className="select"
              options={subSetores}
              //@ts-ignore
              onChange={(e) => {
                setSearchFilters({
                  ...searchFilters,
                  //@ts-ignore
                  subsetor: e.value,
                });
                setSelectedValues({
                  ...selectedValues,
                  // @ts-ignore
                  subsetor: e.value,
                });
              }}
              value={returnSelectValue(selectedValues.subsetor, subSetores)}
              placeholder="Todos"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderTop: 'transparent',
                  borderRight: 'transparent',
                  borderLeft: 'transparent',
                  '&:hover': {
                    borderTop: 'transparent',
                    borderRight: 'transparent',
                    borderLeft: 'transparent',
                  },
                  marginTop: '0',
                  marginLeft: '0',
                  display: 'flex',
                  width: 200,
                  height: 56,
                  padding: '4px 4px 4px 12px',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flex: '1 0 0',
                  borderBottom: '1px solid var(--Gray-gray-600, #6A717E)',
                  background: 'transparent',
                }),
                container: (baseStyles) => ({
                  ...baseStyles,
                  borderRadius: 0,
                  background: 'transparent',
                }),
                input: (baseStyles) => ({
                  ...baseStyles,
                  color: 'none',
                  fontSize: 16,
                  background: 'transparent',
                }),
                placeholder: (base) => ({
                  ...base,
                  background: 'transparent',
                }),
                singleValue: (base) => ({
                  ...base,
                  background: 'transparent',
                }),
                valueContainer: (base) => ({
                  ...base,
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingTop: 8,
                  paddingBottom: 8,
                  background: 'transparent',
                }),
                indicatorSeparator: () => ({
                  display: 'none',
                }),
                dropdownIndicator: (base) => ({
                  ...base,
                  color: '#808080',
                  background: 'transparent',
                }),
                menu: (base) => ({
                  ...base,
                  marginTop: '0',
                  position: 'absolute',
                  top: 'calc(100% + 10px)',
                  left: '0',
                  width: '100%',
                  background: '#FFF', 
                  border: '1px solid #C0C3C9', 
                  borderRadius: '8px', 
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', 
                  zIndex: '2',
                  padding: '10px'
                }),
              }}
            />
          </ContentInputFilters>
        </FiltersStyles>
        <ButtonShowMoreFilters
          onClick={() => setShowAllFilters(!showAllFilters)}
          type="button"
          style={{ 
            appearance: 'none', 
            WebkitAppearance: 'none', 
            MozAppearance: 'none', 
            borderRadius: '8px',
            background: 'none', 
            padding: 0, 
            cursor: 'pointer',
            width: '40px',
            height: '40px',
            border: '1px solid #C0C3C9',
            marginTop: '0%',
            marginLeft: '55rem',
            position: 'absolute',
            zIndex: '3',
          }}
          >
          <img src={filtros} alt="Filtros" style={{marginLeft: '20%',}} />
          {showAllFilters ? '' : ''}
        </ButtonShowMoreFilters>
        {showAllFilters && (
          <div style={{ 
            position: 'absolute', 
            top: 'calc(100% + 10px)', // Ajusta a posição abaixo do botão
            left: '0', 
            width: '100%', 
            background: '#FFF', 
            border: '1px solid #C0C3C9', 
            borderRadius: '8px', 
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', 
            zIndex: '3',
            padding: '10px',
            }}>
            <div style={{
              marginTop:'0',
              display:'flex',
              flexDirection:'row',
              
            }}>
              <ShowMoreFilters>
                <ContentInputShowFilters2>
                  <label>Dividend Yield/DY </label>
                  <Slider
                    min={0}
                    max={20}
                    step={0.01}
                    onChange={handleChangeDyFilter}
                    value={typeof dyValueFilter === 'number' ? dyValueFilter : 0}
                    className="slider"
                  />
                  <span>Acima de {dyValueFilter}%</span>
                </ContentInputShowFilters2>
                <ContentInputShowFilters2>
                  <label>EBITDA</label>
                  <Slider
                    max={160}
                    onChange={handleChangeEbitdaFilter}
                    value={typeof ebitdaFilter === 'number' ? ebitdaFilter : 0}
                    className="slider"
                    step={null}
                    // @ts-ignore
                    tooltip={tooltip}
                    marks={marksMobile}
                  />
                  {/* <span>{maskValueEbitda(ebitdaFilter)}</span> */}
                </ContentInputShowFilters2>
                <ContentInputShowFilters2>
                  <label>Lucro líquido</label>
                  <Slider
                    onChange={handleChangeLucroFilter}
                    value={typeof lucroFilter === 'number' ? lucroFilter : 0}
                    className="slider"
                    step={null}
                    max={160}
                    // @ts-ignore
                    tooltip={tooltip}
                    marks={marksMobile}
                  />
                  {/* <span>{maskValueEbitda(lucroFilter)}</span> */}
                </ContentInputShowFilters2>
                <ContentInputShowFilters2>
                  <label>Dívida líquida</label>
                  <Slider
                    onChange={handleChangeDividaLiquidaFilter}
                    value={
                      typeof dividaLiquidaFilter === 'number'
                        ? dividaLiquidaFilter
                        : 0
                    }
                    draggableTrack
                    className="slider"
                    step={null}
                    max={100}
                    // @ts-ignore
                    tooltip={tooltipDividaLiquida}
                    included={false}
                    marks={marksDividaLiquidaMobile}
                  />
                  {/* <span>{maskValueDivida(dividaLiquidaFilter)}</span> */}
                </ContentInputShowFilters2>
                <ContentInputShowFilters2>
                  <label>Dívida EBITDA</label>
                  <Slider
                    onChange={handleChangeDividaEbitdaFilter}
                    value={
                      typeof dividaEbitdaFilter === 'number' ? dividaEbitdaFilter : 0
                    }
                    className="slider"
                    step={1}
                    min={-15}
                    max={15}
                    included={false}
                  />
                  <span>Abaixo de {dividaEbitdaFilter}%</span>
                </ContentInputShowFilters2>
              </ShowMoreFilters>
            </div>
          </div>
          )}
      </AsideContentFilters>
    
  );
}
