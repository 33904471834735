import styled from 'styled-components';

interface IPropsLink {
  isLinked: boolean;
}

export const LinkB3Content = styled.div<IPropsLink>`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  background: ${(props) => (props.isLinked ? '#02CD53' : '#303F4B')};
  padding: 0.75rem 1.5rem;
  width: auto;
  max-width: 1850px;
  max-height: 150px;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
  margin-left: 2.575rem;
  margin-right: 2.575rem;
  margin-bottom: 1rem;
  box-shadow: 0px 4px 4px 0px rgba(80, 80, 80, 0.2);

  img {
    width: 100%;
    max-width: 3rem;
  }

  p {
    font-weight: 500;
    color: ${(props) => props.theme.white};
    font-size: 1.25rem;
    margin: 0;
    line-height: 150%;
  }
  .divider {
    height: 3.125rem;
    width: 1px;

    background: white;
  }
  button {
    all: unset;
    background-color: ${(props) => props.theme.white};
    color: ${(props) =>
      props.isLinked ? '#02CD53' : '#EC6643'}!important;
    font-weight: 700;
    padding: 0.875rem 1.5rem;
    border: 0;
    border-radius: 8px;
    font-size: 1.125rem;
    line-height: 133%;
  }
`;
