import { FiX } from 'react-icons/fi';
import styled from 'styled-components';

export const OutModalStyled = styled.div`
  position: fixed;
  display: flex;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);

  color: ${(props) => props.theme.black};
  top: 0;
  right: 0;
  z-index: 1000000000 !important;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  h1 {
    padding: 0;
  }
`;

interface IPropsHeader {
  titleColor?: string;
}

export const Header = styled.div<IPropsHeader>`
  width: 100%;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  padding: 1rem;
  align-items: center;

  h4 {
    color: #bf1924;
    text-align: center;
    font-family: Poppins;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2rem;
    width: 100%;
  }
`;
export const Close = styled(FiX)`
  font-size: 1.5rem;
  cursor: pointer;
`;

interface IPropsModalStyled {
  size: 'sm' | 'md' | 'lg' | 'xl';
  fullSize: boolean;
}

export const ModalStyled = styled.div<IPropsModalStyled>`
  align-items: center;
  justify-content: center;

  padding: 1rem;
  background: ${(props) => props.theme.white};
  border-radius: 1rem;
  width: ${(props) => (props.fullSize ? '100%' : 'auto')};
  max-width: ${(props) =>
    props.size === 'xl'
      ? '70rem'
      : props.size === 'lg'
      ? '55rem'
      : props.size === 'md'
      ? '40rem'
      : '30rem'};
`;

export const Content = styled.div`
  padding: 1rem;

  max-height: 70vh;

  overflow-y: auto;
  ::-webkit-scrollbar {
    /* display: none; */
  }
`;
