import { Carousel } from 'antd';
import axios from 'axios';
import { CaretLeft, CaretRight } from '@phosphor-icons/react';
import { useEffect, useState } from 'react';
import { LinkB3Card } from '../../../../../components/LinkB3Card';
import { useTitleAndUser } from '../../../../../hooks/userTitle';
import { videosRoutes } from '../../../../../utils/RoutesApi';

import { FeaturedVideo } from './components/FeaturedVideo';

import styles from './styles.module.css';
import { NewsComponentDash } from './components/NewsComponentDash';
import { FeedbackUser } from './components/FeedbackUser';
import curseImg from '../../../../../assets/IC.jpg';
import promo03 from '../../../../../assets/promo-03-carr.png';
import car1 from '../../../../../assets/business/1x1.png';
import car2 from '../../../../../assets/business/car2.jpeg';
import cia from '../../../../../assets/CIA.jpg';
import ir from '../../../../../assets/IR2025_Carrossel2.png';
interface IPropsVideos {
  category: number;
  channel: number;
  channelThumbnail: string;
  channelTitle: string;
  comment: number[];
  date: string;
  description: string;
  dislike: number[];
  id: number;
  like: number[];
  thumbnail: string;
  title: string;
  video_id: string;
  view: number[];
}

export function CarouselDashInfos() {
  //@ts-ignore
  const { user } = useTitleAndUser();
  const [videos, setVideos] = useState<IPropsVideos[]>([]);
  useEffect(() => {
    handleGetLastFiveVideos();
  }, []);

  function handleGetLastFiveVideos() {
    axios
      .get(`${process.env.REACT_APP_API_URL}${videosRoutes.LAST_FIVE_VIDEOS}`)
      .then((response) => {
        setVideos([response.data[0], response.data[1]]);
      })
      .catch((error) => {});
  }

  return (
    <Carousel
      arrows
      nextArrow={<CaretRight size={32} color="black" alt="Próximo slide" />}
      prevArrow={<CaretLeft size={32} color="black" alt="Slide anterior" />}
      autoplay
      autoplaySpeed={5000}
      style={{ height: '100%' }}
      className={styles.Container}
    >
      {/* <div className={styles.CurseContent}>
        <a href="/pagamentos" target="_blank">
          <img src={car1} alt="" />
        </a>
      </div> */}
      {/* <div className={styles.CurseContent}>
        <a href="/pagamentos" target="_blank">
          <img src={car2} alt="" />
        </a>
      </div> */}
      <FeedbackUser />
      {/* <CardCurse /> */}
      {videos.map((video) => (
        <FeaturedVideo video={video} key={video.video_id} />
      ))}
      {/* <FeedbackUser  /> */}
      <div className={styles.CurseContent}>
        <a href="https://www.oinvestidorcompleto.com.br/" target="_blank">
          <img src={curseImg} alt="" />
        </a>
      </div>
      <div className={styles.CurseContent}>
        <a
          href="https://lp.lucianofernandesif.com.br/ebook-cia-0?utm_source=ibox6&utm_medium=banner&utm_campaign=gif-cia-vend-ibox6-banner-org-x-x-x"
          target="_blank"
        >
          <img src={cia} alt="" />
        </a>
      </div>
      <div className={styles.CurseContent}>
        <a
          href="https://pay.hotmart.com/R85734558A?off=jdkgu46p&scr=plataforma"
          target="_blank"
        >
          <img src={ir} alt="" />
        </a>
      </div>
      {/* <div className={styles.CurseContent}>
        <a
          href="/pagamentos"
          // target="_blank"
        >
          <img src={promo03} alt="" />
        </a>
      </div> */}
      {/* <NewsComponentDash /> */}
    </Carousel>
  );
}
