import {
  ArrowLeft,
  CurrencyCircleDollar,
  ListNumbers,
  UserGear,
} from '@phosphor-icons/react';
import { useTitleAndUser } from '../../../../hooks/userTitle';
import DropDown from '../../../DropDown';
import {
  InfoBoxContainer,
  InfoContainer,
  MainAndSideCardContainer,
  MainCardHeader,
  MoreButtonContainer,
  MainCard,
  ContentTab,
  TabComponent,
} from './styles';
import InfoBox from '../../InfoBox';
import { BsCalendar4Week } from 'react-icons/bs';
import { HiOutlineChartSquareBar } from 'react-icons/hi';
import { IPropsDataFundoFii } from './@types';
import { HeaderCardAnalysisFiiMobile } from './HeaderCardAnalysisFiiMobile';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { baseURL } from '../../../../utils/baseURL';
import { financeRoutes } from '../../../../utils/RoutesApi';
import { GraphicNoteFii, IPropsFiiRankActive } from '../GraphicNoteFii';

interface IPropsMainCardAnalysisFii {
  setModalFatos: (isShow: boolean) => void;
  onBackAllFii: Function;
  dataFundo: IPropsDataFundoFii;
  slug: string;
  closePrice: string;
}

export function MainCardAnalysisFii({
  setModalFatos,
  dataFundo,
  onBackAllFii,
  slug,
  closePrice,
}: IPropsMainCardAnalysisFii) {
  // @ts-ignore
  const { permissions } = useTitleAndUser();
  const isAdmin = permissions.includes('Admin');
  const [graphicNotes, setGraphicNotes] = useState<IPropsFiiRankActive>({
    codigo_fii: '',
    data_funcionamento_numerico: 100,
    dividendo_yeld: 100,
    nome_fundo: '',
    nota_final: 100,
    qtde_ativos: 100,
    vacancia_fisica: 100,
    valor_patrimonial_por_cota: 100,
    tipo_fii: '',
    corretora: '',
  });

  useEffect(() => {
    getActivesWithNotes();
  }, []);
  async function getActivesWithNotes() {
    try {
      const response = await axios.get(
        `${baseURL}${financeRoutes.FINANCE_LIST_FIIS_WITH_RANK}`,
        {
          params: {
            nome_ou_codigo: slug,
          },
        }
      );
      setGraphicNotes(response.data.data[0]);
    } catch (error) {}
  }

  return (
    <MainAndSideCardContainer>
      <MainCard>
        {window.screen.width < 909 && (
          <HeaderCardAnalysisFiiMobile
            dataFundo={dataFundo}
            onBackAllFii={onBackAllFii}
            fii={graphicNotes}
          />
        )}

        {isAdmin && (
          <MoreButtonContainer>
            <DropDown>
              <div>
                <a
                  onClick={() => {
                    setModalFatos(true);
                  }}
                  target="_blank"
                  className="ms-2 textDropDownLink"
                >
                  Fatos relevantes
                </a>
              </div>
              <div style={{ width: '300px' }}>
                <a
                  href={
                    dataFundo.site
                      ? `https://${dataFundo.site.replace('https://', '')}`
                      : '#'
                  }
                  target="_blank"
                  className="ms-2 textDropDownLink"
                  style={{ width: '100%' }}
                >
                  Site da administradora
                </a>
              </div>

              <div>
                <a
                  href={`fii/edit/${slug}`}
                  className="ms-2 textDropDownLink"
                  style={{ width: '100%' }}
                >
                  Editar
                </a>
              </div>
            </DropDown>
          </MoreButtonContainer>
        )}
        {window.screen.width > 909 && (
          <button className="backButton" onClick={() => onBackAllFii('', '')}>
            <ArrowLeft weight="bold" />
            {dataFundo.nome_fundo}
          </button>
        )}
        <ContentTab>
          <TabComponent onClick={() => setModalFatos(true)}>
            Fatos relevantes
          </TabComponent>

          <TabComponent
            onClick={() =>
              window.open(
                dataFundo.site
                  ? `https://${dataFundo.site.replace('https://', '')}`
                  : '#'
              )
            }
          >
            Site
          </TabComponent>

          {/* <TabComponent onClick={() => setModalCompareAssets(true)}>
          Comparar ativo
        </TabComponent> */}
        </ContentTab>
        <MainCardHeader>
          <InfoContainer>
            <InfoBox
              label="Ticker:"
              text={dataFundo.codigo_fii}
              icon={
                <ListNumbers
                  size={window.screen.width > 909 ? '23px' : '16px'}
                  color="#EC6643"
                />
              }
            ></InfoBox>
            <InfoBox
              label="Fundação:"
              text={dataFundo.data_funcionamento}
              icon={
                <BsCalendar4Week
                  size={window.screen.width > 909 ? '23px' : '16px'}
                  color="#EC6643"
                />
              }
            ></InfoBox>
            <InfoBox
              label="Administradora:"
              text={dataFundo.nome_administrador}
              icon={
                <UserGear
                  size={window.screen.width > 909 ? '23px' : '16px'}
                  color="#EC6643"
                />
              }
            ></InfoBox>
            <InfoBox
              label="Gestora:"
              text={graphicNotes.corretora}
              icon={
                <UserGear
                  size={window.screen.width > 909 ? '23px' : '16px'}
                  color="#EC6643"
                />
              }
            ></InfoBox>
          </InfoContainer>
          <InfoBoxContainer>
            <InfoBox
              label="Tipo de gestão:"
              text={dataFundo.tipo_gestao}
              icon={
                <UserGear
                  size={window.screen.width > 909 ? '23px' : '16px'}
                  color="#EC6643"
                />
              }
            ></InfoBox>
            <InfoBox
              label="Tipo de fundo:"
              text={graphicNotes.tipo_fii}
              icon={
                <UserGear
                  size={window.screen.width > 909 ? '23px' : '16px'}
                  color="#EC6643"
                />
              }
            ></InfoBox>
            <InfoBox
              label="Setor:"
              text={dataFundo.setor}
              icon={
                <HiOutlineChartSquareBar
                  size={window.screen.width > 909 ? '23px' : '16px'}
                  color="#EC6643"
                />
              }
            ></InfoBox>
            <InfoBox
              label="Cotação:"
              text={closePrice}
              icon={
                <CurrencyCircleDollar
                  size={window.screen.width > 909 ? '23px' : '16px'}
                  color="#EC6643"
                />
              }
            ></InfoBox>
          </InfoBoxContainer>
          {window.screen.width > 909 && (
            <GraphicNoteFii fii={graphicNotes} graphicWidth={300} />
      
          )}
        </MainCardHeader>
      </MainCard>
    </MainAndSideCardContainer>
  );
}
