import axios from 'axios';
import { useEffect, useState } from 'react';
import StringMask from 'string-mask';
import moment from 'moment';

import { chartHelpText } from '../../social/constants';
import BottomCard from './BottomCard';
import ModalChart from '../ModalChart';
import ListFatosRelevantes from '../ListFatosRelevantes';
import { useTitleAndUser } from '../../hooks/userTitle';
import { GraphicContract } from './components/graphics/Contract';
import { financeRoutes } from '../../utils/RoutesApi';
import { FormatLabelContract } from './utils/FormatLabelContract';
import { GraphicDividendos } from './components/graphics/Dividendos';
import { GraphicVacancia } from './components/Vacancia';
import { CardImovel } from './components/CardImoveis';
import FiiPainel from '../../jsx/components/Dashboard/FIIPainel';
import { GraphicImoveis } from './components/graphics/Imoveis';
import { AnalysisFiiLoader } from '../../jsx/components/LoadingModels';
import { ContentGraphics, ContentGraphics2 } from '../ResumoAcao/styles';

import { maskValueCards } from '../ResumoAcao/components/maskValueCards';
import { transformMaskCurrency } from '../../utils/transformMaskCurrency';
import { AboutCompany } from '../ResumoAcao/components/AboutCompany';
import {
  formatNomeFundo,
  formatNomeFundoAdministradora,
} from './utils/formatNomeFundo';
import {
  IContainer,
  Codigo,
  Label,
  GlobalContainer,
  BCardInfoContainer,
  CardContent,
  HeaderDividendosModal,
  CardContentData,
} from './styles';
import { ModalDesigner } from '../ModalDesigner';

import { MainCardAnalysisFii } from './components/MainCardAnalysisFii';
import { GraphicCotationHistory } from '../ResumoAcao/components/graphics/GraphicCotationHistory';
import { GraphicCotation } from '../ResumoAcao/components/GraphicCotation';
import { BottomCardGraphic2 } from './BottomCard2';
import { Tooltip } from 'antd';

const baseURL = process.env.REACT_APP_API_URL;

const ResumoFII = ({
  slug,
  onBackAllFii = (slug, type) => {},
  setSelectedActive = (slug, type) => {},
}) => {
  const { setTitle } = useTitleAndUser();
  const [loading, setLoading] = useState(false);
  const [modalFatos, setModalFatos] = useState(false);
  const [modalLucro, setModalLucro] = useState(false);
  const [modalEbitida, setModalEbitida] = useState(false);
  const [modalDivida, setModalDivida] = useState(false);
  const [modalValorPatrimonio, setModalValorPatrimonio] = useState(false);
  const [modalValorMercado, setModalValorMercado] = useState(false);
  const [modalDividaEbitida, setModalDividaEbitida] = useState(false);
  const [contracts, setContracts] = useState([]);
  const [vacanciaData, setVacanciaData] = useState([]);
  const [imoveisData, setImoveisData] = useState([]);
  const [dataFundo, setDataFundo] = useState({});
  const [totalImoveis, setTotalImoveis] = useState({});
  const [historyData, setHistoryData] = useState([]);
  const [allClosePrice, setAllClosePrice] = useState([]);
  const [valorPatrimonial, setValorPatrimonial] = useState([]);
  const [showModalAboutCompany, setShowModalAboutCompany] = useState(false);
  const [retornosCotacao, setRetornosCotacao] = useState({
    annualized_return: 0,
    five_year_return: 0,
    total_return: 0,
  });
  const [historyCotation, setHistoryCotation] = useState([]);
  const [showModalCotation, setShowModalCotation] = useState(false);
  const [loadingHistoryCotantion, setLoadingHistoryCotantion] = useState(false);

  const [filtersHistoryCotation, setFiltersHistoryCotation] = useState({
    period: 'MAX',
  });
  useEffect(() => {
    getContractsPeriod();
    getVacancia();
    getImoveis();
    getDataFii();
    getDividendos();
    getHistoryFii();
  }, [slug, location.pathname]);
  useEffect(() => {
    definedArryValorPatrimonial();
  }, [historyData, allClosePrice]);

  useEffect(() => {
    getClosePriceLongAgo(filtersHistoryCotation);
  }, [location.pathname, slug, filtersHistoryCotation]);

  useEffect(() => {
    if (dataFundo.nome_fundo) {
      setTitle(dataFundo.nome_fundo);
    } else {
      setTitle('FII');
    }
  }, [dataFundo]);

  async function getClosePriceLongAgo(params = {}) {
    setLoadingHistoryCotantion(true);
    setLoading(true);

    try {
      const response = await axios.get(
        `${baseURL}${financeRoutes.FINANCE_COTATION_LONG_TIME}`,
        {
          params: {
            ticker: slug,
            period: params.period,
          },
        }
      );
      setRetornosCotacao(response.data.slice(response.data.length - 1)[0]);
      setHistoryCotation(
        response.data.slice(0, response.data.length - 1).sort((a, b) => {
          if (a.date < b.date) {
            return -1;
          }
          return true;
        })
      );
    } catch (error) {}
    setLoadingHistoryCotantion(false);
    setLoading(false);
  }

  async function getHistoryFii() {
    setLoading(true);
    await axios
      .get(`${baseURL}${financeRoutes.FINANCE_HISTORY_FII}`, {
        params: {
          codigo_fii: slug,
        },
      })
      .then((response) => {
        const newData = response.data
          .map((item) => {
            return {
              ...item,
              caixa: Number(item.caixa.toFixed(2)),
              dy: Number(item.dy.toFixed(2)),
              patrimonio_liquido: Number(item.patrimonio_liquido.toFixed(2)),
              rendimento_por_cota: Number(item.rendimento_por_cota.toFixed(2)),
              valor_ativo: Number(item.valor_ativo.toFixed(2)),
            };
          })
          .sort((a, b) => {
            if (a.competencia < b.competencia) {
              return -1;
            }
            return true;
          });
        setHistoryData(newData);
      })
      .catch((err) => {});
    setLoading(false);
  }

  async function getContractsPeriod() {
    setLoading(true);
    await axios
      .get(`${baseURL}${financeRoutes.FINANCE_DISTRATOS}`, {
        params: {
          search: slug,
        },
      })
      .then((response) => {
        let comp = response.data.map((item) => {
          return item;
        })[0];
        let newData = response.data.filter(
          (item) =>
            item.competencia === comp.competencia &&
            item.tipo === '@percentReceitaImovel'
        );

        newData = newData.map((item) => {
          FormatLabelContract(item);
          item.valor = item.valor * 100;
          return item;
        });

        newData = newData.sort((a, b) => {
          if (a.valor > b.valor) {
            return -1;
          } else {
            return true;
          }
        });
        setLoading(false);
        setContracts(newData);
      })
      .catch((err) => {
        setLoading(false);
      });
  }
  async function getVacancia() {
    await axios
      .get(`${baseURL}${financeRoutes.FINANCE_VACANCIA_CONSOLIDATED}`, {
        params: { search: slug },
      })
      .then((response) => {
        let newData = response.data.map((item) => {
          item.ValorPercOcupacao = item.ValorPercOcupacao * 100;

          item.ValorPercVacanciaFinanceira =
            item.ValorPercVacanciaFinanceira === null
              ? null
              : item.ValorPercVacanciaFinanceira * 100;

          item.ValorPercVacanciaFisica = item.ValorPercVacanciaFisica * 100;
          return item;
        });
        newData = newData.sort((a, b) => {
          if (a.DataVancancia < b.DataVancancia) {
            return -1;
          } else {
            return true;
          }
        });
        setVacanciaData(newData);
      })
      .catch((err) => {});
  }

  async function getImoveis() {
    await axios
      .get(`${baseURL}${financeRoutes.FINANCE_IMOVEIS}`, {
        params: {
          codigo_fii: slug,
        },
      })
      .then((response) => {
        let totalAtivos = response.data.length;
        let totalUn = response.data.reduce((acc, imovel) => {
          return acc + imovel.NumUnidades;
        }, 0);
        setTotalImoveis({ ativos: totalAtivos, unidades: totalUn });

        setImoveisData(response.data);
      })
      .catch((err) => {});
  }
  async function getDataFii() {
    await axios
      .get(`${baseURL}${financeRoutes.FINANCE_FIIS}`, {
        params: {
          search: slug,
        },
      })
      .then((response) => {
        let fundo = response.data[0];
        var formatter = new StringMask('00.000.000/0000-00', {
          reverse: true,
        });
        fundo.nome_fundo = formatNomeFundo(fundo.nome_fundo);
        fundo.nome_administrador = formatNomeFundoAdministradora(
          fundo.nome_administrador
        );
        fundo.CNPJ = formatter.apply(fundo.CNPJ);
        axios
          .get(`${baseURL}${financeRoutes.FINANCE_PRICE_FII}`, {
            params: {
              search: slug,
            },
          })
          .then((res) => {
            setAllClosePrice(res.data);
            let priceUn = Number(res.data[0]?.Close.toFixed(2)) || 0;
            priceUn = transformMaskCurrency(priceUn);
            fundo.closePrice = priceUn;

            setDataFundo((state) => {
              return { ...state, ...fundo };
            });
          });
      })
      .catch((err) => {});
  }

  async function getDividendos() {
    await axios
      .get(`${baseURL}${financeRoutes.FINANCE_FII_RESULT}`, {
        params: {
          search: slug,
        },
      })
      .then((response) => {
        setDataFundo((state) => {
          return { ...state, ...response.data[0] };
        });
      })
      .catch((err) => {});
  }

  function definedArryValorPatrimonial() {
    if (allClosePrice.length > 0 && historyData.length > 0) {
      const closePriceDefinedArr =
        allClosePrice.map((item) => {
          return {
            ...item,
            Data: moment(item.Data).format('MM/YYYY'),
          };
        }) ?? [];
      let definedArrayPatrimonio = historyData
        .map((item) => {
          const closePriceFiltered = closePriceDefinedArr?.[0];

          const closePriceNumber = closePriceFiltered?.Close
            ? Number(closePriceFiltered.Close.toFixed(2))
            : 0;

          return {
            patrimonio_liquido: Number(item.patrimonio_liquido.toFixed(2)),
            patrimonio_bruto: Number(
              (closePriceNumber * item.numero_cotas_emitidas).toFixed(2)
            ),
            closePrice: closePriceNumber,
            refDate: item.competencia,
            valorPatrimonial: item.vpc,
            cotas: item.numero_cotas_emitidas,
            pVP: item.p_vp,
          };
        })
        .sort((a, b) => {
          if (a.refDate < b.refDate) {
            return -1;
          }
          return true;
        });
      if (valorPatrimonial.length < 1) {
        setValorPatrimonial(
          definedArrayPatrimonio.filter((item) => !Number.isNaN(item.pVP))
        );
      }
      console.log({ definedArrayPatrimonio });
    }
  }
  const defineChartCollor = (arr) => {
    const ok = '#52C41A';
    const danger = '#C61722';

    if (arr[arr.length - 1]?.value > arr[0]?.value) {
      return ok;
    }
    return danger;
  };

  const defineChartCollorHistoryCotation = (arr) => {
    const ok = '#52C41A';
    const danger = '#C61722';

    if (arr[arr.length - 1]?.close > arr[0]?.close) {
      return ok;
    }
    return danger;
  };

  const lastActiveHistory = historyData[historyData.length - 1];
  const valorAcumulado12Meses = historyData.reduce((acc, item) => {
    return acc + item.rendimento_por_cota;
  }, 0);
  const percentualAcumulado12Meses = lastActiveHistory
    ? (valorAcumulado12Meses / lastActiveHistory?.valor_ativo) * 100
    : 0;

  return loading ? (
    <AnalysisFiiLoader />
  ) : (
    <>
      <GlobalContainer>
        <MainCardAnalysisFii
          dataFundo={dataFundo}
          onBackAllFii={(slug, type) => {
            onBackAllFii(slug, type);
          }}
          setModalFatos={setModalFatos}
          slug={slug}
          closePrice={transformMaskCurrency(
            historyCotation.length >= 1
              ? historyCotation[historyCotation.length - 1].close
              : 0
          )}
        />

        <ContentGraphics>
          {totalImoveis.unidades && (
            <BottomCard
              openModal={setModalDividaEbitida}
              helpText={chartHelpText.imoveis}
              label="Imóveis"
              path="M480 192H592C618.5 192 640 213.5 640 240V464C640 490.5 618.5 512 592 512H48C21.49 512 0 490.5 0 464V144C0 117.5 21.49 96 48 96H64V24C64 10.75 74.75 0 88 0C101.3 0 112 10.75 112 24V96H176V24C176 10.75 186.7 0 200 0C213.3 0 224 10.75 224 24V96H288V48C288 21.49 309.5 0 336 0H432C458.5 0 480 21.49 480 48V192zM576 368C576 359.2 568.8 352 560 352H528C519.2 352 512 359.2 512 368V400C512 408.8 519.2 416 528 416H560C568.8 416 576 408.8 576 400V368zM240 416C248.8 416 256 408.8 256 400V368C256 359.2 248.8 352 240 352H208C199.2 352 192 359.2 192 368V400C192 408.8 199.2 416 208 416H240zM128 368C128 359.2 120.8 352 112 352H80C71.16 352 64 359.2 64 368V400C64 408.8 71.16 416 80 416H112C120.8 416 128 408.8 128 400V368zM528 256C519.2 256 512 263.2 512 272V304C512 312.8 519.2 320 528 320H560C568.8 320 576 312.8 576 304V272C576 263.2 568.8 256 560 256H528zM256 176C256 167.2 248.8 160 240 160H208C199.2 160 192 167.2 192 176V208C192 216.8 199.2 224 208 224H240C248.8 224 256 216.8 256 208V176zM80 160C71.16 160 64 167.2 64 176V208C64 216.8 71.16 224 80 224H112C120.8 224 128 216.8 128 208V176C128 167.2 120.8 160 112 160H80zM256 272C256 263.2 248.8 256 240 256H208C199.2 256 192 263.2 192 272V304C192 312.8 199.2 320 208 320H240C248.8 320 256 312.8 256 304V272zM112 320C120.8 320 128 312.8 128 304V272C128 263.2 120.8 256 112 256H80C71.16 256 64 263.2 64 272V304C64 312.8 71.16 320 80 320H112zM416 272C416 263.2 408.8 256 400 256H368C359.2 256 352 263.2 352 272V304C352 312.8 359.2 320 368 320H400C408.8 320 416 312.8 416 304V272zM368 64C359.2 64 352 71.16 352 80V112C352 120.8 359.2 128 368 128H400C408.8 128 416 120.8 416 112V80C416 71.16 408.8 64 400 64H368zM416 176C416 167.2 408.8 160 400 160H368C359.2 160 352 167.2 352 176V208C352 216.8 359.2 224 368 224H400C408.8 224 416 216.8 416 208V176z"
            >
              <BCardInfoContainer>
                <IContainer style={{ fontSize: '20px' }}>
                  <Label>Unidades:</Label>
                  <Codigo style={{ fontSize: '20px' }}>
                    {totalImoveis.unidades}
                  </Codigo>
                </IContainer>
              </BCardInfoContainer>
              <BCardInfoContainer>
                <IContainer style={{ fontSize: '20px' }}>
                  <Label>Ativos:</Label>
                  <Codigo style={{ fontSize: '20px' }}>
                    {totalImoveis.ativos}
                  </Codigo>
                </IContainer>
              </BCardInfoContainer>
            </BottomCard>
          )}
          {vacanciaData.length > 0 && (
            <BottomCard
              openModal={setModalDivida}
              label="Vacância"
              helpText={chartHelpText.vacancia}
              path="M0 48C0 21.49 21.49 0 48 0H336C362.5 0 384 21.49 384 48V232.2C344.9 264.5 320 313.3 320 368C320 417.5 340.4 462.2 373.3 494.2C364.5 505.1 351.1 512 336 512H240V432C240 405.5 218.5 384 192 384C165.5 384 144 405.5 144 432V512H48C21.49 512 0 490.5 0 464V48zM80 224C71.16 224 64 231.2 64 240V272C64 280.8 71.16 288 80 288H112C120.8 288 128 280.8 128 272V240C128 231.2 120.8 224 112 224H80zM160 272C160 280.8 167.2 288 176 288H208C216.8 288 224 280.8 224 272V240C224 231.2 216.8 224 208 224H176C167.2 224 160 231.2 160 240V272zM272 224C263.2 224 256 231.2 256 240V272C256 280.8 263.2 288 272 288H304C312.8 288 320 280.8 320 272V240C320 231.2 312.8 224 304 224H272zM64 144C64 152.8 71.16 160 80 160H112C120.8 160 128 152.8 128 144V112C128 103.2 120.8 96 112 96H80C71.16 96 64 103.2 64 112V144zM176 96C167.2 96 160 103.2 160 112V144C160 152.8 167.2 160 176 160H208C216.8 160 224 152.8 224 144V112C224 103.2 216.8 96 208 96H176zM256 144C256 152.8 263.2 160 272 160H304C312.8 160 320 152.8 320 144V112C320 103.2 312.8 96 304 96H272C263.2 96 256 103.2 256 112V144zM640 368C640 447.5 575.5 512 496 512C416.5 512 352 447.5 352 368C352 288.5 416.5 224 496 224C575.5 224 640 288.5 640 368zM492.7 323.3L521.4 352H432C423.2 352 416 359.2 416 368C416 376.8 423.2 384 432 384H521.4L492.7 412.7C486.4 418.9 486.4 429.1 492.7 435.3C498.9 441.6 509.1 441.6 515.3 435.3L571.3 379.3C577.6 373.1 577.6 362.9 571.3 356.7L515.3 300.7C509.1 294.4 498.9 294.4 492.7 300.7C486.4 306.9 486.4 317.1 492.7 323.3V323.3z"
            >
              <BCardInfoContainer>
                <IContainer style={{ fontSize: '20px' }}>
                  <Label>Física:</Label>
                  {vacanciaData[vacanciaData.length - 1]
                    ?.ValorPercVacanciaFisica === null ? (
                    <Tooltip title="O iBox6 utiliza apenas dados oficiais, registrados na B3. Em caso de ausência de algum dado na plataforma, a informação pode ser obtida nos relatórios do fundo.">
                      <Codigo style={{ fontSize: '20px' }}>N/D</Codigo>
                    </Tooltip>
                  ) : (
                    <Codigo style={{ fontSize: '20px' }}>
                      {vacanciaData[
                        vacanciaData.length - 1
                      ]?.ValorPercVacanciaFisica.toFixed(2)}
                      %
                    </Codigo>
                  )}
                </IContainer>
              </BCardInfoContainer>
              <BCardInfoContainer>
                <IContainer style={{ fontSize: '20px' }}>
                  <Label>Financeira:</Label>
                  {vacanciaData[vacanciaData.length - 1]
                    ?.ValorPercVacanciaFinanceira === null ? (
                    <Tooltip title="O iBox6 utiliza apenas dados oficiais, registrados na B3. Em caso de ausência de algum dado na plataforma, a informação pode ser obtida nos relatórios do fundo.">
                      <Codigo style={{ fontSize: '20px' }}>N/D</Codigo>
                    </Tooltip>
                  ) : (
                    <Codigo style={{ fontSize: '20px' }}>
                      {vacanciaData[
                        vacanciaData.length - 1
                      ]?.ValorPercVacanciaFinanceira.toFixed(2) + '%'}
                    </Codigo>
                  )}
                </IContainer>
              </BCardInfoContainer>
            </BottomCard>
          )}
          {contracts.length > 0 && (
            <BottomCard
              openModal={setModalLucro}
              label="Contratos"
              helpText={chartHelpText.contratos}
              className="step10"
              path="M292.7 342.3C289.7 345.3 288 349.4 288 353.7V416h62.34c4.264 0 8.35-1.703 11.35-4.727l156.9-158l-67.88-67.88L292.7 342.3zM568.5 167.4L536.6 135.5c-9.875-10-26-10-36 0l-27.25 27.25l67.88 67.88l27.25-27.25C578.5 193.4 578.5 177.3 568.5 167.4zM256 0v128h128L256 0zM256 448c-16.07-.2852-30.62-9.359-37.88-23.88c-2.875-5.875-8-6.5-10.12-6.5s-7.25 .625-10 6.125l-7.749 15.38C187.6 444.6 181.1 448 176 448H174.9c-6.5-.5-12-4.75-14-11L144 386.6L133.4 418.5C127.5 436.1 111 448 92.45 448H80C71.13 448 64 440.9 64 432S71.13 416 80 416h12.4c4.875 0 9.102-3.125 10.6-7.625l18.25-54.63C124.5 343.9 133.6 337.3 144 337.3s19.5 6.625 22.75 16.5l13.88 41.63c19.75-16.25 54.13-9.75 66 14.12C248.5 413.2 252.2 415.6 256 415.9V347c0-8.523 3.402-16.7 9.451-22.71L384 206.5V160H256c-17.67 0-32-14.33-32-32L224 0H48C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48V448H256z"
            >
              <BCardInfoContainer>
                <IContainer>
                  <Label>{contracts[0]?.periodo}:</Label>
                  <Codigo>{contracts[0]?.valor.toFixed(2)}%</Codigo>
                </IContainer>
              </BCardInfoContainer>
              <BCardInfoContainer>
                <IContainer>
                  <Label>{contracts[1]?.periodo}:</Label>
                  <Codigo>{contracts[1]?.valor.toFixed(2)}%</Codigo>
                </IContainer>
              </BCardInfoContainer>
            </BottomCard>
          )}
        </ContentGraphics>
        <ContentGraphics2>
          <BottomCardGraphic2
            openModal={setModalEbitida}
            label="Dividendos"
            helpText={''}
            value2={transformMaskCurrency(
              historyData.length > 0
                ? historyData[historyData.length - 1].rendimento_por_cota
                : 0
            )}
            titleValue2="Rendimento"
            titleValue="DY"
            data={historyData.map((item) => {
              return {
                value: item.dy,
                label: item.competencia,
              };
            })}
            fillcolor={defineChartCollor(
              historyData.map((item) => {
                return {
                  value: item.dy,
                };
              })
            )}
            value={
              lastActiveHistory ? lastActiveHistory.dy.toFixed(2) + '%' : 0
            }
            title={`${dataFundo.dy ? dataFundo.dy.toFixed(2) : '0'}%`}
          />
          <BottomCardGraphic2
            openModal={setModalValorMercado}
            label="Valor patrimonial"
            helpText={''}
            value2={
              valorPatrimonial.length > 0
                ? valorPatrimonial[valorPatrimonial.length - 1].pVP
                : 0
            }
            titleValue="Patrimônio líquido"
            titleValue2="P/VP"
            data={valorPatrimonial.map((item) => {
              return {
                value: item.patrimonio_liquido,
                label: item.refDate,
              };
            })}
            fillcolor={defineChartCollor(
              valorPatrimonial.map((item) => {
                return {
                  value: item.patrimonio_liquido,
                  label: item.refDate,
                };
              })
            )}
            value={
              valorPatrimonial.length > 0
                ? maskValueCards(
                    valorPatrimonial[
                      valorPatrimonial.length - 1
                    ].patrimonio_liquido.toFixed(0)
                  )
                : 0
            }
          />
          <GraphicCotation
            // helpText={chartHelpText.ROE}
            openModal={() => setShowModalCotation(true)}
            data={historyCotation}
            label="Histórico de cotação"
            value={transformMaskCurrency(
              historyCotation.length >= 1
                ? historyCotation[historyCotation.length - 1].close
                : 0
            )}
            fillcolor={defineChartCollorHistoryCotation(historyCotation)}
            loading={loadingHistoryCotantion}
          />
        </ContentGraphics2>
      </GlobalContainer>

      {modalValorMercado && (
        <ModalChart
          title="Valor patrimonial"
          setState={setModalValorMercado}
          state={modalValorMercado}
        >
          <GraphicDividendos
            slug={slug}
            data={historyData.map((item) => {
              return {
                label: item.competencia,
                value: item.patrimonio_liquido,
              };
            })}
            typeValue="money"
            title="Patrimônio líquido"
            highestValue={historyData
              .map((item) => {
                return {
                  label: item.competencia,
                  value: item.patrimonio_liquido,
                };
              })
              .sort((a, b) => {
                if (a.value > b.value) {
                  return -1;
                }
                return true;
              })}
          />
          <GraphicDividendos
            slug={slug}
            data={valorPatrimonial.map((item) => {
              return {
                value: item.valorPatrimonial,
                label: item.refDate,
              };
            })}
            normalMoney
            typeValue="money"
            title="VPC"
            highestValue={valorPatrimonial
              .map((item) => {
                return {
                  value: item.valorPatrimonial,
                  label: item.refDate,
                };
              })
              .sort((a, b) => {
                if (a.value > b.value) {
                  return -1;
                }
                return true;
              })}
          />

          <GraphicDividendos
            slug={slug}
            data={valorPatrimonial.map((item) => {
              return {
                value: item.pVP,
                label: item.refDate,
              };
            })}
            typeValue="noMask"
            title="P/VP"
            highestValue={valorPatrimonial
              .map((item) => {
                return {
                  value: item.pVP,
                  label: item.refDate,
                };
              })
              .sort((a, b) => {
                if (a.value > b.value) {
                  return -1;
                }
                return true;
              })}
          />
        </ModalChart>
      )}
      {modalValorPatrimonio && (
        <ModalChart
          title="Valor patrimonial"
          setState={setModalValorPatrimonio}
          state={modalValorPatrimonio}
        >
          <GraphicDividendos
            slug={slug}
            data={historyData.map((item) => {
              return {
                label: item.competencia,
                value: item.patrimonio_liquido,
              };
            })}
            typeValue="money"
            title="Patrimônio líquido"
            highestValue={historyData
              .map((item) => {
                return {
                  label: item.competencia,
                  value: item.patrimonio_liquido,
                };
              })
              .sort((a, b) => {
                if (a.value > b.value) {
                  return -1;
                }
                return true;
              })}
          />
        </ModalChart>
      )}

      {modalLucro && (
        <ModalChart
          id="Contratos"
          title="Contratos"
          helptext={chartHelpText.contratos}
          setState={setModalLucro}
          state={modalLucro}
        >
          <GraphicContract slug={slug} contracts={contracts} />
        </ModalChart>
      )}
      {modalEbitida && (
        <ModalChart
          title="Dividendos"
          setState={setModalEbitida}
          state={modalEbitida}
        >
          <HeaderDividendosModal>
            <CardContentData>
              <p>Último dividendo </p>
              <strong>
                {transformMaskCurrency(lastActiveHistory.rendimento_por_cota)}
              </strong>
            </CardContentData>
            <CardContentData>
              <p>DY Últ. dividendo </p>
              <strong>{lastActiveHistory.dy.toFixed(2)}%</strong>
            </CardContentData>
            <CardContentData>
              <p>Div. por cota </p>
              <strong>{dataFundo.total_rendimento_12}</strong>
              <span>Últ. 12 meses</span>
            </CardContentData>
            <CardContentData>
              <p>Dividend Yield </p>
              <strong>{dataFundo.dy_12}%</strong>
              <span>Últ. 12 meses</span>
            </CardContentData>
          </HeaderDividendosModal>
          <br />
          <GraphicDividendos
            slug={slug}
            data={historyData.map((item) => {
              return {
                value: item.rendimento_por_cota,
                label: item.competencia,
              };
            })}
            typeValue="money"
            title="Rendimento"
            highestValue={historyData
              .map((item) => {
                return {
                  value: item.rendimento_por_cota,
                };
              })
              .sort((a, b) => {
                if (a.value > b.value) {
                  return -1;
                }
                return true;
              })}
          />

          <GraphicDividendos
            slug={slug}
            data={historyData.map((item) => {
              return {
                value: item.dy,
                label: item.competencia,
              };
            })}
            typeValue="percent"
            title="Dividend Yield/DY"
            highestValue={historyData
              .map((item) => {
                return {
                  value: item.dy,
                };
              })
              .sort((a, b) => {
                if (a.value > b.value) {
                  return -1;
                }
                return true;
              })}
          />
        </ModalChart>
      )}

      <GraphicVacancia
        dataGraphic={vacanciaData}
        state={modalDivida}
        setState={setModalDivida}
        title="Vacância"
        slug={slug}
      />

      {modalDividaEbitida && (
        <ModalChart
          id="Imóveis"
          title="Imóveis"
          setState={setModalDividaEbitida}
          helptext={chartHelpText.imoveis}
          state={modalDividaEbitida}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <GraphicImoveis imovels={imoveisData} />

            <CardContent>
              {imoveisData.map((imovel) => (
                <CardImovel imovel={imovel} />
              ))}
            </CardContent>
          </div>
        </ModalChart>
      )}

      {modalFatos && (
        <ModalChart
          id="Fatos Relevantes"
          title="Fatos Relevantes"
          setState={setModalFatos}
          state={modalFatos}
          helptext="Fatos Relevantes sobre a empresa."
          onClick={() => {}}
        >
          <ListFatosRelevantes slug={slug} type="FII" />
        </ModalChart>
      )}
      {dataFundo.setor && (
        <FiiPainel
          seg={{
            search: true,
            value: dataFundo?.setor.split(' ')[0],
          }}
          isComponent
          onSelectedActive={setSelectedActive}
        />
      )}

      <ModalDesigner
        isOpen={showModalAboutCompany}
        setState={setShowModalAboutCompany}
        id="sobre"
        size="xl"
        title={`Sobre ${slug}`}
      >
        <AboutCompany slug={slug} />
      </ModalDesigner>

      <ModalDesigner
        id="cotacaohistorico"
        isOpen={showModalCotation}
        setState={setShowModalCotation}
        size="xl"
        title="Cotação histórica"
      >
        <GraphicCotationHistory
          headerValues={retornosCotacao}
          data={historyCotation}
          color={defineChartCollorHistoryCotation(historyCotation)}
          filtersHistoryCotation={filtersHistoryCotation}
          setFiltersHistoryCotation={setFiltersHistoryCotation}
          loading={loadingHistoryCotantion}
        />
      </ModalDesigner>
    </>
  );
};

export default ResumoFII;
