import styled from "styled-components";

export const ContentDataHeader = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 1rem;
  p {
    margin: 0;
  }
`;

export const CardContentData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  border-top: 1px solid #EC6643;
  min-width: 8rem;
  width: 15rem;
  box-shadow: 1px 1px 6px 0px rgba(48, 63, 75, 0.42);

  padding: 0.5rem;

  p {
    margin: 0;
    font-size: 1rem;
    color: ${(props) => props.theme['gray-500']};
  }
  strong {
    font-weight: 600;
    font-size: 1rem;
    color: ${(props) => props.theme['gray-500']};
  }
`;