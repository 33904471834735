import { useState } from 'react';
import { filterTableSorte } from '../../../../../../../utils/filterTableSort';
import { IPropsPositionAll } from '../../../../../../../utils/@types/wallet';
import { useActives } from '../../../../../../../hooks/useActives';
import { CaretDown, CaretUp, Eye, EyeSlash } from '@phosphor-icons/react';
import { Switch } from 'antd';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import { financeRoutes } from '../../../../../../../utils/RoutesApi';
import { IPropsAllPosition } from '../..';
import { transformMaskCurrency } from '../../../../../../../utils/transformMaskCurrency';
import { CompanyNameContent } from './styles';
import { useActivesWallet } from '../../../../../../pages/Wallet/hooks/ActivesHooks';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontFamily: 'Poppins, sans-serif',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: 'Poppins, sans-serif',
  },
}));

const StyledBody = styled(TableBody)(
  ({ theme }) => `
  overflow-y: auto;
  max-height:500px;

`
);

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

interface IPropsTableSelectedMattress {
  header: {
    label: string;
    active?: string;
  }[];
  dataTable: IPropsAllPosition[];
  ASC: Function;
  DESC: Function;
  onSubmitUpdateMattress: (id: string) => void;
}

const baseURL = process.env.REACT_APP_API_URL;

export function TableSelectedMattress({
  header,
  dataTable,
  ASC,
  DESC,
  onSubmitUpdateMattress,
}: IPropsTableSelectedMattress) {
  //@ts-ignore
  const { showActive } = useActives();
  const { updateWallet } = useActivesWallet();
  const [showActiveName, setShowActiveName] = useState<boolean>(false);

  const [loadingRequestForAddMattress, setLoadingRequestForAddMattress] =
    useState<boolean>(false);
  const [attOrdemTable, setAttOrdemTable] = useState<boolean>(false);

  async function handleChangeMattressStatus(active: IPropsPositionAll) {
    setLoadingRequestForAddMattress(true);
    if (!active.reservadeemergencia) {
      const data = {
        ativo: active.tickerSymbol,
        tp_acao: active.productTypeName,
        type: 'all',
        corretora: '',
      };

      await axios
        .post(`${baseURL}${financeRoutes.FINANCE_EMERGENCY_RESERVE_ADD}`, data)
        .then((response) => {
          onSubmitUpdateMattress(active.tickerSymbol);
          updateWallet();
        })
        .catch((err) => {});
      return setLoadingRequestForAddMattress(false);
    } else {
      await axios
        .delete(
          `${baseURL}${financeRoutes.FINANCE_EMERGENCY_RESERVE_REMOVE(
            active.tickerSymbol.replace('%', '').replace('/', '--')
          )}`
        )
        .then((response) => {
          onSubmitUpdateMattress(active.tickerSymbol);
          updateWallet();
        })
        .catch((err) => {});
      return setLoadingRequestForAddMattress(false);
    }
  }

  return (
    <TableContainer component={Paper} sx={{ maxHeight: 400 }}>
      <Table aria-label="customized table" stickyHeader>
        <TableHead>
          <TableRow>
            {header.map((item) => (
              <StyledTableCell key={item.label}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                    fontSize: '0.95rem',
                  }}
                >
                  {item.label}
                  {item.active && (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <CaretUp
                        size={14}
                        color="black"
                        onClick={() => {
                          setAttOrdemTable(!attOrdemTable);
                          return ASC(
                            filterTableSorte({
                              data: dataTable,
                              dir: 'asc',
                              //@ts-ignore
                              key: item.active,
                            })
                          );
                        }}
                      />
                      <CaretDown
                        size={14}
                        color="black"
                        onClick={() => {
                          setAttOrdemTable(!attOrdemTable);

                          return DESC(
                            filterTableSorte({
                              data: dataTable,
                              dir: 'desc',
                              //@ts-ignore
                              key: item.active,
                            })
                          );
                        }}
                      />
                    </div>
                  )}

                  {item.label === 'Ativo' &&
                    (!showActiveName ? (
                      <EyeSlash
                        size={20}
                        color="black"
                        onClick={() => setShowActiveName(!showActiveName)}
                      />
                    ) : (
                      <Eye
                        size={20}
                        color="black"
                        onClick={() => setShowActiveName(!showActiveName)}
                      />
                    ))}
                </div>
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <StyledBody>
          {dataTable.length > 0 ? (
            dataTable?.map((item) => (
              <StyledTableRow key={item.tickerSymbol}>
                {showActive || showActiveName ? (
                  <StyledTableCell>
                    <CompanyNameContent>
                      <strong title={item.tickerSymbol}>
                        {item.tickerSymbol}
                      </strong>

                      <span title={item.corporationName}>
                        {item.corporationName}
                      </span>
                    </CompanyNameContent>
                  </StyledTableCell>
                ) : (
                  <StyledTableCell>*******</StyledTableCell>
                )}

                <StyledTableCell>
                  <Switch
                    size="small"
                    loading={loadingRequestForAddMattress}
                    checked={item.reservadeemergencia}
                    //@ts-ignore
                    size="default"
                    onClick={() => handleChangeMattressStatus(item)}
                    checkedChildren="ON"
                    unCheckedChildren="OFF"
                  />
                </StyledTableCell>

                <StyledTableCell>
                  {showActive
                    ? transformMaskCurrency(item.updateValue)
                    : 'R$ *****'}
                </StyledTableCell>
              </StyledTableRow>
            ))
          ) : (
            <h1 style={{ fontSize: '1rem', margin: '1rem 0.5rem' }}>
              Ainda não há dados
            </h1>
          )}
        </StyledBody>
      </Table>
    </TableContainer>
  );
}
