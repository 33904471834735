import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Dropdown, Modal, Button as ButtonBoot } from 'react-bootstrap';
import ReactLoading from 'react-loading';

import {
  List,
  MagnifyingGlass,
  Plugs,
  EyeSlash,
  Eye,
  ArrowLeft,
} from '@phosphor-icons/react';
import { Autocomplete } from '@mui/material';
import axios from 'axios';
import { Dropdown as DropdownAntd, Menu } from 'antd';
import { useNewTheme } from '../../../../hooks/useNewTheme';
import { HeaderMenu } from '../HeaderMenu';
import { LinkAccountB3 } from '../../../components/Dashboard/Settings/LinkAccountB3';
import { SwitchAutoImport } from '../../../../components/SwitchAutoImport';
import { useTitleAndUser } from '../../../../hooks/userTitle';
import { useActives } from '../../../../hooks/useActives';
import { Notifications } from './components/Notifications';
import {
  Container,
  ContentUser,
  ContainerShowMenu,
  ContainerOptionSearch,
} from './styles';
import { ModalDesigner } from '../../../../components/ModalDesigner';
import imageVoltar from '../../../../assets/Voltar.png';
import imageLogo from '../../../../assets/Logo icon.svg';
import userdefault from '../../../../assets/User.png';

const baseUrl = process.env.REACT_APP_API_URL;

interface IPropsHeader {
  shortSidebar: boolean;
}

interface IPropsSearchOption {
  Asset: string;
  Mercado: string;
  NomeAtivo: string;
  key: string;
}

export function Header({ shortSidebar }: IPropsHeader) {
  //@ts-ignore
  const { user, title } = useTitleAndUser();
  //@ts-ignore
  const { isMobile } = useNewTheme();
  //@ts-ignore
  const { showActive, setShowActive } = useActives();

  const history = useHistory();

  const [styledHeader, setStyledHeader] = useState(true);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [inputSearch, setInputSearch] = useState('');
  const [hasResult, setHasResult] = useState(false);
  const [searchOptions, setSearchOptions] = useState<IPropsSearchOption[]>([]);
  const [modalLinkAccountB3, setModalLinkAccountB3] = useState(false);
  //@ts-ignore

  useEffect(() => {
    if (!isMobile) {
      setShowMenu(false);
    }
  }, [isMobile]);
  useEffect(() => {
    if (
      title === '' ||
      (location.pathname.match('user-posts') &&
        //@ts-ignore
        location.pathname.match('user-posts')[0] === 'user-posts')
    ) {
      setStyledHeader(false);
    } else {
      setStyledHeader(true);
    }
    function scrollPosition() {
      if (
        title === '' ||
        (location.pathname.match('user-posts') &&
          //@ts-ignore
          location.pathname.match('user-posts')[0] === 'user-posts')
      ) {
        window.scrollY > 250 ? setStyledHeader(true) : setStyledHeader(false);
      } else {
        window.scrollY > 80 && setStyledHeader(true);
      }
    }
    window.addEventListener('scroll', scrollPosition);
    return () => {
      window.removeEventListener('scroll', scrollPosition);
    };
  }, [title]);

  useEffect(() => {
    if (inputSearch.length > 0) {
      const timeOutId = setTimeout(() => {
        searchFunction(inputSearch);
      }, 500);
      return () => clearTimeout(timeOutId);
    } else {
      setSearchOptions([]);
      setHasResult(false);
    }
  }, [inputSearch]);

  const searchFunction = async (search: string) => {
    setLoadingSearch(true);
    setHasResult(true);
    await axios
      .get(`${baseUrl}/Finance/todosativosbusca?search=${search}&page_size=5`)
      .then((response) => {
        let newData = response.data.data;
        newData = newData.map((item: IPropsSearchOption) => {
          return {
            ...item,
            key: search,
          };
        });
        setSearchOptions(newData);
        setLoadingSearch(false);
      })
      .catch(() => {
        setHasResult(false);
        setSearchOptions([]);
        setLoadingSearch(false);
      });
  };

  const menu = (
    <Menu
      style={{ top: '10px' }}
      items={[
        {
          label: <SwitchAutoImport />,
          key: '0',
        },
      ]}
    />
  );
  function handleBackPage() {
    window.history.back();
  }

  const renderSearchMenu = () => {
    return (
      <Autocomplete
        getOptionLabel={(option: IPropsSearchOption) => option.key}
        onInputChange={(e, value) => setInputSearch(value)}
        options={searchOptions}
        open={hasResult}
        loadingText={
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ReactLoading type="balls" color="black" width={60} height={60} />
          </div>
        }
        fullWidth
        loading={loadingSearch}
        noOptionsText="Nenhum resultado encontrado"
        renderInput={(params) => (
          <div ref={params.InputProps.ref} className="searchContainer">
            <MagnifyingGlass
              size={24}
              color="#fff"
              style={{ cursor: 'pointer' }}
            />
            <input
              type="text"
              {...params.inputProps}
              placeholder="Buscar ativo"
              className="searchInput"
            />
          </div>
        )}
        renderOption={(props, option: IPropsSearchOption) => {
          return (
            <ContainerOptionSearch
              href={
                option.Mercado === 'ACAO'
                  ? `/acao/${option.Asset}`
                  : `/fii/${option.Asset}`
              }
              className="searchOptionContainer"
            >
              <p className="searchOptionText">
                <strong>{option.Asset}</strong> {option.NomeAtivo}
              </p>
            </ContainerOptionSearch>
          );
        }}
      />
    );
  };
  const imgProfile = () => {
    let img = user.profile?.image;
    if (img) {
      if (
        !!img.match(
          'https://storage.googleapis.com/ibox6-wordpress.appspot.com/'
        )
      ) {
        return img;
      } else {
        img = img;
        return img;
      }
    }
  };
  const profile = imgProfile();

  function handleSetNewShowActive() {
    localStorage.setItem('isShowActive', JSON.stringify(!showActive));
    setShowActive(!showActive);
  }

  let path = window.location.pathname;
  // @ts-ignore
  path = path.split('/');
  path = path[path.length - 1];

  return (
    <>
      <Container
        titleHeader={title}
        shortSidebar={shortSidebar}
        fixed={styledHeader}
        screenWidth={window.screen.width}
        isMobile={isMobile}
        automatico={user.automatico}
        carteira={user.carteira}
        isFullScreen={path === 'wallet'}
        isBlackScreen={window.location.pathname === '/admin-painel/dashboard'}
      >
        <nav>
          <img className="imageLogo" alt="Logo" src={imageLogo} />
          <div className="backTitle">
            {window.history.length > 2 && window.history.state !== null && (
              <button
                onClick={handleBackPage}
                title="Voltar página"
                type="button"
              >
                <img alt="voltar" src={imageVoltar} />
              </button>
            )}

            {/********************************************************************************  */}

            {title !== '' && (
              <h3>
                {title === 'Página inicial'
                  ? `Boas-vindas, ${user.Name.split(' ')[0]} `
                  : title}
              </h3>
            )}
          </div>
          <div className="userContent">
            <>
              {/* {!isMobile && renderSearchMenu()} */}
              <ul>
                {user.carteira === 'automatico' ? (
                  <DropdownAntd
                    overlay={menu}
                    trigger={['click']}
                    placement="bottomRight"
                  >
                    <a onClick={(e) => e.preventDefault()} className="linkB3">
                      <Plugs
                        size={isMobile ? '1.5rem' : '2rem'}
                        color="#ffffff"
                        onClick={() => {
                          if (user.carteira !== 'automatico') {
                            return setModalLinkAccountB3(true);
                          }
                        }}
                      />
                      <span className="auto" />
                    </a>
                  </DropdownAntd>
                ) : (
                  <Dropdown as="li" className="linkB3">
                    <Dropdown.Toggle
                      as="a"
                      variant=""
                      className="i-false c-pointer"
                    >
                      <Plugs
                        size={isMobile ? '1.5rem' : '2rem'}
                        color="#ffffff"
                        onClick={() => {
                          if (user.carteira !== 'automatico') {
                            return setModalLinkAccountB3(true);
                          }
                        }}
                      />
                      <span className="auto" />
                    </Dropdown.Toggle>
                    {user.carteira === 'automatico' && (
                      <Dropdown.Menu bsPrefix="switch">
                        <SwitchAutoImport />
                      </Dropdown.Menu>
                    )}
                  </Dropdown>
                )}
                <li>
                  {showActive ? (
                    <>
                      <Eye
                        size={isMobile ? '1.5rem' : '2rem'}
                        color="#ffffff"
                        onClick={handleSetNewShowActive}
                        style={{ cursor: 'pointer' }}
                      />
                    </>
                  ) : (
                    <>
                      <EyeSlash
                        size={isMobile ? '1.5rem' : '2rem'}
                        color="#ffffff"
                        onClick={handleSetNewShowActive}
                        style={{ cursor: 'pointer' }}
                      />
                    </>
                  )}
                </li>

                <Notifications />
                {isMobile && (
                  <div className="contentIcons">
                    <List
                      size={isMobile ? '1.5rem' : '2rem'}
                      color="#ffffff"
                      onClick={() => setShowMenu(true)}
                    />
                  </div>
                )}
              </ul>
            </>

            {!isMobile && (
              <ContentUser
                onClick={() => history.push('/recommended-posts')}
                title={title}
              >
                <img
                  src={
                    user
                      ? user?.profile?.image
                        ? `${profile}`
                        : userdefault
                      : userdefault
                  }
                  alt={user.Name ?? 'user'}
                  style={{ width: '30px', height: '30px', marginRight: '-15%' }}
                />
                {window.screen.width > 480 && (
                  <h4 style={{ display: 'none' }}>{user.Name.split(' ')[0]}</h4>
                )}
              </ContentUser>
            )}
          </div>
        </nav>
      </Container>

      <ContainerShowMenu show={showMenu}>
        <div className="red" />
        <div className="blue" />
        <HeaderMenu setShowMenu={setShowMenu}>{renderSearchMenu()}</HeaderMenu>
      </ContainerShowMenu>

      <ModalDesigner
        id="linkModal"
        isOpen={modalLinkAccountB3}
        setState={setModalLinkAccountB3}
        title="Vincular conta B3"
        size="sm"
      >
        <LinkAccountB3 onCloseModal={setModalLinkAccountB3} />
      </ModalDesigner>
    </>
  );
}
