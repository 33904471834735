import axios from 'axios';
import { useForm } from 'react-hook-form';
import ReactApexChart from 'react-apexcharts';
import { Col, InputNumber, Progress, Row, Slider, Tooltip } from 'antd';
import { Info, Warning } from '@phosphor-icons/react';
import { useEffect, useState } from 'react';
import { walletRoutes } from '../../../../utils/RoutesApi';
import { handleToast } from '../../../../components/Toast';
import { useActives } from '../../../../hooks/useActives';
import { useNewTheme } from '../../../../hooks/useNewTheme';
import { BarChartLoader } from '../../LoadingModels';
import { CardGraphic, Container } from './styles';

import { ButtonDesign } from '../../../../components/ButtonDesign';

export function PortfolioStrategy() {
  const { handleSubmit } = useForm();
  const { shortSidebar } = useNewTheme();
  const { percentage, loadingActive } = useActives();
  const [action, setAction] = useState(0);

  const [fiis, setFiis] = useState(0);
  const [rendaFixa, setRendaFixa] = useState(0);
  const [total, setTotal] = useState(0);
  const [checkIfItIsBalanced, setCheckIfItIsBalanced] = useState(false);

  let compare = {
    series: [
      {
        name: 'Ideal',
        data: [
          action ? action.toFixed(2) : 0,
          fiis ? fiis.toFixed(2) : 0,
          rendaFixa ? rendaFixa.toFixed(2) : 0,
        ],
      },
      {
        name: 'Atual',
        data: [percentage.action, percentage.fii, percentage.rendaFixa],
      },
    ],
    options: {
      labels: ['Ações', 'Fiis', 'Renda Fixa'],

      chart: {
        type: 'bar',
      },

      fill: {
        opacity: 1,
      },
      dataLabels: {
        style: {
          colors: ['black'],
        },

        formatter: (val) => {
          return `${val}%`;
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: ['black'],
          },
          formatter: (val) => {
            return `${val}%`;
          },
        },
      },
      tooltip: {
        enabled: false,
        y: {
          formatter: (val) => {
            return `${val}%`;
          },
        },
      },
      grid: {
        borderColor: '#111',
        strokeDashArray: 0,
      },
      colors: ['#2469A8', '#F8B700'],
      xaxis: {
        labels: {
          style: {
            colors: ['black', 'black', 'black'],
          },
        },
      },
      legend: {
        position: 'bottom',
        horizontalAlign: 'right',
      },
      responsive: [
        {
          breakpoint: 1350,
          options: {
            chart: {
              width: shortSidebar ? 550 : 730,
            },
            legend: {
              position: 'bottom',
              horizontalAlign: 'right',
            },
          },
        },
        {
          breakpoint: 1310,
          options: {
            chart: {
              width: shortSidebar ? 500 : 680,
            },
            legend: {
              position: 'bottom',
              horizontalAlign: 'right',
            },
          },
        },
        {
          breakpoint: 1260,
          options: {
            chart: {
              width: shortSidebar ? 450 : 630,
            },
            legend: {
              position: 'bottom',
              horizontalAlign: 'right',
            },
          },
        },
        {
          breakpoint: 1210,
          options: {
            chart: {
              width: shortSidebar ? 400 : 580,
            },
            legend: {
              position: 'bottom',
              horizontalAlign: 'right',
            },
          },
        },

        {
          breakpoint: 1160,
          options: {
            chart: {
              width: shortSidebar ? 350 : 530,
            },
            legend: {
              position: 'bottom',
              horizontalAlign: 'right',
            },
          },
        },
        {
          breakpoint: 1110,
          options: {
            chart: {
              width: shortSidebar ? 300 : 480,
            },
            legend: {
              position: 'bottom',
              horizontalAlign: 'right',
            },
          },
        },
        {
          breakpoint: 1060,
          options: {
            chart: {
              width: shortSidebar ? 650 : 850,
            },
            legend: {
              position: 'bottom',
              horizontalAlign: 'right',
            },
          },
        },
        {
          breakpoint: 990,
          options: {
            chart: {
              width: shortSidebar ? 600 : 800,
            },
            legend: {
              position: 'bottom',
              horizontalAlign: 'right',
            },
          },
        },
        {
          breakpoint: 940,
          options: {
            chart: {
              width: shortSidebar ? 550 : 750,
            },
            legend: {
              position: 'bottom',
              horizontalAlign: 'right',
            },
          },
        },
        {
          breakpoint: 890,
          options: {
            chart: {
              width: shortSidebar ? 500 : 700,
            },
            legend: {
              position: 'bottom',
              horizontalAlign: 'right',
            },
          },
        },
        {
          breakpoint: 840,
          options: {
            chart: {
              width: shortSidebar ? 450 : 650,
            },
            legend: {
              position: 'bottom',
              horizontalAlign: 'right',
            },
          },
        },
        {
          breakpoint: 790,
          options: {
            chart: {
              width: shortSidebar ? 400 : 600,
            },
            legend: {
              position: 'bottom',
              horizontalAlign: 'right',
            },
          },
        },
        {
          breakpoint: 740,
          options: {
            chart: {
              width: shortSidebar ? 350 : 550,
            },
            legend: {
              position: 'bottom',
              horizontalAlign: 'right',
            },
          },
        },
        {
          breakpoint: 690,
          options: {
            chart: {
              width: shortSidebar ? 300 : 500,
            },
            legend: {
              position: 'bottom',
              horizontalAlign: 'right',
            },
          },
        },
        {
          breakpoint: 640,
          options: {
            chart: {
              width: shortSidebar ? 250 : 450,
            },
            legend: {
              position: 'bottom',
              horizontalAlign: 'right',
            },
          },
        },
        {
          breakpoint: 590,
          options: {
            chart: {
              width: shortSidebar ? 200 : 400,
            },
            legend: {
              position: 'bottom',
              horizontalAlign: 'right',
            },
          },
        },
        {
          breakpoint: 540,
          options: {
            chart: {
              width: shortSidebar ? 150 : 350,
            },
            legend: {
              position: 'bottom',
              horizontalAlign: 'right',
            },
          },
        },
        {
          breakpoint: 490,
          options: {
            chart: {
              width: shortSidebar ? 100 : 300,
            },
            legend: {
              position: 'bottom',
              horizontalAlign: 'right',
            },
          },
        },
        {
          breakpoint: 440,
          options: {
            chart: {
              width: 300,
            },
            legend: {
              position: 'bottom',
              horizontalAlign: 'right',
            },
          },
        },
        {
          breakpoint: 390,
          options: {
            chart: {
              width: 280,
              height: 200,
            },
            legend: {
              position: 'bottom',
              horizontalAlign: 'right',
            },
          },
        },
        {
          breakpoint: 360,
          options: {
            chart: {
              width: 240,
              height: 200,
            },
            legend: {
              position: 'bottom',
              horizontalAlign: 'right',
            },
          },
        },
        {
          breakpoint: 320,
          options: {
            chart: {
              width: 200,
              height: 150,
            },
            legend: {
              position: 'bottom',
              horizontalAlign: 'right',
            },
          },
        },
      ],
    },
  };

  useEffect(() => {
    handleGetConfigStrategyWallet();
  }, []);
  useEffect(() => {
    setCheckIfItIsBalanced(
      percentage.action >= action &&
        percentage.fii >= fiis &&
        percentage.rendaFixa >= rendaFixa
    );
  }, [action, fiis, rendaFixa]);

  function handleGetConfigStrategyWallet() {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api${walletRoutes.WALLET_GET_CONFIG_STRATEGY}`
      )
      .then((response) => {
        let data = response.data.data;
        setFiis(data.PorcentagemFII);
        setAction(data.PorcentagemAcoes);
        setRendaFixa(data.PorcentagemRendaVariavel);
        setTotal(
          data.PorcentagemFII +
            data.PorcentagemAcoes +
            data.PorcentagemRendaVariavel
        );
      })
      .catch((err) => {});
  }

  function handleChangeActionPercentage(percentagem) {
    if (fiis + rendaFixa + percentagem > 100) {
      setAction(100 - rendaFixa - fiis);
      setTotal(100);
    } else {
      setAction(percentagem);
      setTotal(fiis + rendaFixa + percentagem);
    }
  }
  function handleChangeFiiPercentage(percentagem) {
    if (action + rendaFixa + percentagem > 100) {
      setFiis(100 - rendaFixa - action);
      setTotal(100);
    } else {
      setFiis(percentagem);
      setTotal(rendaFixa + action + percentagem);
    }
  }
  function handleChangeRendaFixaPercentage(percentagem) {
    if (fiis + action + percentagem > 100) {
      setRendaFixa(100 - action - fiis);
      setTotal(100);
    } else {
      setRendaFixa(percentagem);
      setTotal(fiis + action + percentagem);
    }
  }

  function submit(data) {
    if (total === 100) {
      data.PorcentagemFII = fiis;
      data.PorcentagemAcoes = action;
      data.PorcentagemRendaVariavel = rendaFixa;

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/api${walletRoutes.WALLET_CONFIG_STRATEGY}`,
          data
        )
        .then((response) => {
          handleGetConfigStrategyWallet();
          return handleToast(false, response.data.message);
        })
        .catch((err) => {});
    } else {
      return handleToast(true, 'Você deve atingir 100% da estratégia ');
    }
  }

  return (
    <Container>
      <div
        className="card"
        style={{ padding: '1.25rem', width: '25rem', borderRadius: '4px' }}
      >
        <form onSubmit={handleSubmit(submit)}>
          <Tooltip
            title={
              <p style={{ margin: 0 }}>
                Você poderá criar para cada ativo da sua carteira um percentual
                específico, que entrara como exceção na distribuição automática
                dos ativos.
                <br />A distribuição da carteira deverá ser efetuada melhor
                estratégia para seu perfil de investidor e será base de
                comparação para seus ativos registrados na base de dados da B3.
              </p>
            }
            placement="bottom"
          >
            <div className="titleStrategy">
              <h2>Distribuição da carteira</h2>
              <Info size="1.5rem" color="black" />
            </div>
          </Tooltip>
          <br />

          <div className="contentSlider">
            <p>Ação</p>
            <Row>
              <Col
                span={
                  window.screen.width <= 740
                    ? 17
                    : window.screen.width <= 1060
                    ? 20
                    : 12
                }
              >
                <Slider
                  min={0}
                  max={100}
                  onChange={handleChangeActionPercentage}
                  value={typeof action === 'number' ? action : 0}
                />
              </Col>
              <Col span={4}>
                <InputNumber
                  min={0}
                  max={100}
                  style={{
                    margin: '0 1rem',
                  }}
                  value={action ? action.toFixed(2) : 0}
                  onChange={handleChangeActionPercentage}
                  className="editInput"
                />
              </Col>
            </Row>
          </div>
          <div className="contentSlider">
            <p>Fii</p>
            <Row>
              <Col
                span={
                  window.screen.width <= 740
                    ? 17
                    : window.screen.width <= 1060
                    ? 20
                    : 12
                }
              >
                <Slider
                  min={0}
                  max={100}
                  onChange={handleChangeFiiPercentage}
                  value={typeof fiis === 'number' ? fiis : 0}
                />
              </Col>
              <Col span={4}>
                <InputNumber
                  min={0}
                  max={100}
                  style={{
                    margin: '0 1rem',
                  }}
                  value={fiis ? fiis.toFixed(2) : 0}
                  onChange={handleChangeFiiPercentage}
                  className="editInput"
                />
              </Col>
            </Row>
          </div>

          <div className="contentSlider">
            <p>Renda Fixa</p>
            <Row>
              <Col
                span={
                  window.screen.width <= 740
                    ? 17
                    : window.screen.width <= 1060
                    ? 20
                    : 12
                }
              >
                <Slider
                  min={0}
                  max={100}
                  onChange={handleChangeRendaFixaPercentage}
                  value={typeof rendaFixa === 'number' ? rendaFixa : 0}
                />
              </Col>
              <Col span={4}>
                <InputNumber
                  min={0}
                  max={100}
                  style={{
                    margin: '0 1rem',
                  }}
                  value={rendaFixa ? rendaFixa.toFixed(2) : 0}
                  onChange={handleChangeRendaFixaPercentage}
                  className="editInput"
                />
              </Col>
            </Row>
          </div>

          <div className="totalSlide">
            <h4>Total</h4>
            <Row>
              <strong>{total.toFixed(2)}%</strong>
              <Progress
                percent={total.toFixed(2)}
                status="active"
                showInfo={false}
              />
            </Row>
          </div>
          <br />
          <div style={{ width: '100%' }}>
            <ButtonDesign
              style={{ width: 'calc(100% - 2rem - 4px)', maxWidth: 'none' }}
              type="submit"
            >
              Salvar estratégia
            </ButtonDesign>
          </div>
        </form>
      </div>
      <CardGraphic balanced={checkIfItIsBalanced}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <h2>Estratégia X Carteira</h2>
          {!checkIfItIsBalanced && (
            <Tooltip
              placement="bottom"
              title={
                <p style={{ margin: 0 }}>
                  Este gráfico demonstra a comparação entre sua estratégia e a
                  sua alocação de ativos,para adequação da sua carteira conforme
                  sua estratégia, efetue o balanceamento na aba de simulação de
                  aportes.
                </p>
              }
            >
              <Warning size={30} color="red" />
            </Tooltip>
          )}
        </div>
        {loadingActive ? (
          <BarChartLoader />
        ) : (
          <ReactApexChart
            options={compare.options}
            series={compare.series}
            type="bar"
            width={580}
            height={352}
          />
        )}
      </CardGraphic>
    </Container>
  );
}
