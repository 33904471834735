import { Info, Label, Name, InfoDataContainer, InfoData } from './styles';

const InfoBox = ({ icon, text, label, className = '' }) => {
  return (
    <Info className={className}>
      <Label>
        {icon}
        {label}
      </Label>
      <InfoDataContainer>
        <InfoData
          style={
            label === 'Ticker:'
              ? {
                  background: '#EC6643',
                  color: 'white',
                  padding: '.5rem',
                  borderRadius: '29px',
                  fontSize: '1rem',
                }
              : {}
          }
        >
          {text}
        </InfoData>
      </InfoDataContainer>
    </Info>
  );
};

export default InfoBox;
