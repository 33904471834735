import styled from 'styled-components';

export const MainCard = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.white};
  width: 100%;
  box-shadow: ${(props) => props.theme['card-shadow-2']};
  border-radius: 4px;
  padding: 1rem;
  height: 100%;
  .backButton {
    all: unset;
    line-height: 0;
    color: ${(props) => props.theme['gray-700']};
    font-size: 1.25rem;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;

    font-size: 1.5rem;
    font-weight: 500;
    color: #707070;
    margin-bottom: 1rem;
  }
`;

export const MainCardHeader = styled.div`
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  /* padding: 0px 145px 0px 60px; */

  background-color: ${(props) => props.theme.white};
`;

export const InfoContainer = styled.div`
  color: rgba(43, 42, 42, 1);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 1rem;

  h1 {
    font-size: 1.2rem;
    margin: 0;
  }
`;

export const Name = styled.h1`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.25rem;
  margin: 0;
  max-width: 25ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
export const Codigo = styled.h1`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 700;

  font-size: 1.25rem;
`;

export const Label = styled.p`
  margin: 0px;
  font-size: 0.875rem;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;

  @media (max-width: 480px) {
    font-size: 0.675rem;
  }
`;
export const IContainer = styled.div`
  * {
    margin: 0px;
  }
  display: flex;
  flex-direction: column;
`;
export const NContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-left: 2px black;
`;

export const NomeCodigoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 0.5rem;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const Percent = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  color: rgba(43, 42, 42, 1);
  width: auto;
  /* margin-bottom: 20px; */
`;

export const InfoBoxContainer = styled.div`
  color: rgba(43, 42, 42, 1);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;

  gap: 1rem;
`;

export const InfoData = styled.h3`
  margin: 0px;
  padding: 0px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;

  color: #2b2a2a;

  @media (max-width: 480px) {
    font-size: 0.75rem;
  }
`;

export const InfoDataContainer = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const MoreButtonContainer = styled.div`
  position: absolute;
  cursor: pointer;
  top: 15px;
  right: 15px;
`;

export const SideChartContainer = styled.div`
  background: ${(props) => props.theme.white};
  border-radius: 4px;
  padding: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;

  align-items: center;

  cursor: pointer;
`;

export const SideChartHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  /* line-height: 22px; */
`;
export const SideChartBody = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 1rem;
  width: 100%;
  h1 {
    font-size: 1.2rem;
  }
`;
export const SideChartInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 32px;
`;

export const MainAndSideCardContainer = styled.div`
  @media (max-width: 1220px) {
    flex-wrap: wrap;
    flex-direction: column;
    height: auto;
  }
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  /* height: 425px; */
`;

export const BottomCardContainer = styled.div`
  background: ${(props) => props.theme.white};
  border-radius: 4px;
  padding: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: ${(props) => props.theme['card-shadow-2']};
`;

export const BottomCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
`;

export const BottomCardInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
`;

export const CardInfoValue = styled.h3`
  margin: 0px;
  padding: 0px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  width: 100%;
`;

export const GlobalContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 5.9%;
  height: auto;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1rem;
  gap: 1rem;
  z-index:2;
`;

export const ToolTipContainer = styled.div`
  /* width: 400px; */
  text-align: justify;
  padding: 10px;

  @media (max-width: 450px) {
    width: 300px;
  }
`;

export const BCardInfoContainer = styled.div`
  width: 100%;

  gap: 1rem;
  display: flex;
  justify-content: center;
`;
export const SCardInfoContainer = styled.div`
  width: 100%;

  gap: 1rem;
  display: flex;

  justify-content: space-between;
`;

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: red;
`;

export const CardContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  max-height: 500px;
  justify-content: center;
  gap: 1rem;
`;

export const ContentTextModal = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
  h1 {
    font-size: 1rem;
    color: ${(props) => props.theme['gray-700']};
    margin: 0;
    width: 100%;
  }
  p {
    margin: 0;
    font-size: 1rem;
    color: ${(props) => props.theme['gray-700']};
  }
`;

export const ButtonSelectGraphicType = styled.button`
  border: 1px solid
    ${(props) => (props.selected ? '#EC6643' : 'rgba(48, 63, 75, 0.42)')};
  outline: none;
  padding: 0.5rem;
  border-radius: 4px;
  cursor: pointer;
  transition: filter 0.3s;
  svg {
    color: #EC6643;
  }
  :hover {
    filter: brightness(0.9);
  }
`;

export const BottomCardInfo2 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 32px;
`;

export const BottomCard = styled.div`
  cursor: pointer;
  background: ${(props) => props.theme.white};
  border-radius: 4px;
  padding: 1rem 1.5rem;

  height: 100%;
  max-height: 11.25rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: ${(props) => props.theme['card-shadow-2']};
  gap: 1rem;
`;

export const BottomCardHeader2 = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  z-index: 2;
  span {
    font: 700 1rem 'Poppins', sans-serif;
    color: ${(props) => props.theme['gray-700']};
  }
`;
export const CardInfoValue2 = styled.h3`
  margin: 0px;
  padding: 0px;
  font: 700 1.5rem 'Poppins', sans-serif;
  color: ${(props) => props.theme['gray-700']};
`;

export const ContentValuesCardBottom = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
  width: 100%;
  padding: 0 1rem;
`;

export const ContentTextValue = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  span {
    margin: 0px;
    font-size: 0.875rem;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    color: #969ba0;
    @media (max-width: 480px) {
      font-size: 0.675rem;
    }
  }
  strong {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1.25rem;
    color: black;
  }
`;

export const HeaderDividendosModal = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;

`;

export const CardContentData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  border-top: 1px solid #EC6643;
  min-width: 8rem;
  width: 15rem;
  box-shadow: 1px 1px 6px 0px rgba(48, 63, 75, 0.42);

  padding: 0.5rem;

  p {
    margin: 0;
    font-size: 1rem;
    color: ${(props) => props.theme['gray-500']};
  }
  strong {
    font-weight: 600;
    font-size: 1.25rem;
    color: ${(props) => props.theme['gray-500']};
  }
  span{
       margin: 0;
    font-size: 0.825rem;
    color: ${(props) => props.theme['gray-500']};
  }
`;